import { DatePicker, Popover, Tooltip } from "antd";
import React from "react";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomAvatar from "../../Core/CommonV2/CustomAvatar";
import AlertIcon from "../../Core/svgV2/AlertIcon";
import TimeEstimatesLayout from "./TimeEstimatesLayout";
import TimeEstimatesMenu from "./TimeEstimatesMenu";
import ProjectTimeEstimatesScrren from "./ProjectTimeEstimatesScrren";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { PlusIcon, PlusIconBold, PlusIconBoldBlack, PlusIconBoldGray } from "../../Core/svgV2/PlusIcon";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import useSearch from "../../Core/hooks/useSearch";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
const { RangePicker } = DatePicker;
import 'antd/dist/reset.css'; 

export const ProjectStartDateEndDate = ({ startDate, endDate, type }) => {
  const [date, setDate] = useState(type === "Start" ? startDate : endDate);
  const [showSelect, setShowSelect] = useState(false);

  useEffect(() => {
    setDate(type === "Start" ? startDate : endDate);
  }, [type, startDate, endDate]);

  const handleDateChange = (date: dayjs.Dayjs | null, dateString: string) => {
    setDate(dateString);
    setShowSelect(false);
  };

  const dateRender = (current: dayjs.Dayjs) => {
    const isHighlighted = (date: dayjs.Dayjs) => {
      const formattedDate = date.format('YYYY-MM-DD');
      return formattedDate === dayjs(startDate).format('YYYY-MM-DD') || formattedDate === dayjs(endDate).format('YYYY-MM-DD');
    };

    return (
      <div
        className={`ant-picker-cell ${isHighlighted(current) ? 'highlighted-date' : ''}`}
        style={{ 
          display: 'flex',
          width: '30px',
          height: '30px',
          padding: '5px 4px',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '15px',
          backgroundColor: isHighlighted(current) ? '#165ABF' : '#F9FBFE',
          color: isHighlighted(current) ? '#FFFFFF' : '#101828',
          fontSize: '12px', 
          textAlign: 'center'
        }}
      >
        {current.date()}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start gap-4 w-[320px] self-stretch">
      {!showSelect && date ? (
        <button
          className="flex p-1.5 items-center gap-2 self-stretch rounded border border-[#DDE5ED] bg-[#F9FBFE] text-[#101828]"
          onClick={() => setShowSelect(true)}
          style={{ width: '100%' }}
        >
          <div className="flex items-center gap-2 flex-1 justify-start">
            <div className="text-[#101828] text-sm font-light leading-5">
              {date}
            </div>
          </div>
        </button>
      ) : (
        <DatePicker
          className="flex p-1.5 items-center gap-2 self-stretch rounded border border-[#7DC7FF] bg-[#F9FBFE] shadow-xs focus:ring-2 focus:ring-[#D1E9FF] custom-date-picker"
          style={{
            width: '100%',
            maxWidth: '400px',  
            maxHeight: '230px',
            overflow: 'auto',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #D1E9FF'
          }}
          format="DD MMM YYYY"
          autoFocus
          open
          placeholder={date}
          suffixIcon={null}
          onChange={handleDateChange}
          dateRender={dateRender}
        />
      )}
    </div>
  );
};

export const ProjectCount = ( {count} )=>{
  return (
    <div className="w-[1117px] h-6 pr-[1025px] pb-0.5 justify-start items-center inline-flex">
      <div className="self-stretch justify-start items-center gap-3 inline-flex">
        <div className="text-[#667084] text-sm font-medium font-['Outfit'] leading-tight">Projects</div>
          <div className="mix-blend-multiply justify-start items-start flex">
              <div className="px-2 py-0.5 bg-[#eff8ff] rounded-2xl justify-center items-center flex">
                <div className="text-center text-[#1659bf] text-xs font-medium font-['Outfit'] leading-[18px]">{count}</div>
              </div>
          </div>
      </div>
    </div>
  );
}

export const ProjectContainer = ({project})=>{
  const navigate = useNavigate();
  const { projectId } = useParams();

  return (
  <div
    onClick={(e) => {
      e.preventDefault();
      navigate(`/time-estimates/${project.id}`);
    }}
    role="button"
  >
    <div className="w-[211px] h-[74px] flex-1 border border-[#DDE5ED] rounded-[8px] bg-[#F9FBFE]">
      <div className="w-[211px] h-[74px] flex-1 border border-[#DDE5ED] rounded-[8px] bg-[#F9FBFE] flex items-center p-3">

          {project.projectImage ? (
            <div className="w-[20px] h-[20px] flex-shrink-0 rounded-[12px] border border-[#EDF3F9]">
              <CustomAvatar
                title={project.title}
                src={project.projectImage}
                style={{ width: '20px', height: '20px', flexShrink: 0 }}
              />
            </div>
          ) : (
            <div className="w-[20px] h-[20px] flex-shrink-0 rounded-[12px] border border-[#EDF3F9] bg-gray-200">
              {/* Placeholder if there's no image */}
            </div>
          )}


          <div className="ml-3 text-[#344054] text-sm font-medium font-['Outfit'] leading-[20px]">
            {project.title}
          </div>

          <div className="ml-auto flex w-[14px] h-[14px] flex-col items-center gap-[4px] flex-shrink-0">

            <Tooltip 
              title={"Estimates are pending"} 
              placement="bottom"
              style= {{
                  display: 'flex',
                  padding: '4px 8px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  borderRadius: '4px',
                  background: '#000000', 
                  color: '#ffffff',
                }}
            >
              <AlertIcon
                height="14px"
                width="14px"
              />
            </Tooltip>
          </div>
      </div>
    </div>
  </div>
  )

}

export const ProjectDropDown = ({project,onSelect = () => {console.log('true')} })=>{
  const navigate = useNavigate();

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options); 
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onSelect();
        navigate(`/time-estimates/${project.id}`);
      }}
      role="button"
    >    
      <div className="p-2 px-4 flex p-2 gap-3 items-center self-stretch border-b border-gray-100 bg-white hover:bg-[#F0F5FA]">
        <div className="w-6 h-6 rounded-full border border-gray-100">
            <EllipseIcon style={{
                width:'24px',
                height:'24px',
                fill:'#FCFDFF'
            }}/>
            <div className="flex w-5 pt-1 pr-1 pb-2 pl-1 justify-center items-center">
              {/* logo to add  */}
            </div>                   
        </div>

        <div className="flex flex-col justify-center items-start gap-0.5 flex-1">
            <div className="self-stretch text-gray-700 text-sm font-medium leading-5">
                {project.title}
            </div>
            <div className="self-stretch text-gray-500 text-xs font-normal leading-normal font-outfit">
                {formatDate(project.startDate)} - {formatDate(project.endDate)}
            </div>
        </div>

        <div className="flex w-[75px] justify-end items-center">
            <div className="flex px-2 justify-center items-center gap-1 flex-1 rounded-full bg-[#D1E9FF]">
                <div className="text-[#165ABF] text-center text-xs font-medium leading-[18px] font-outfit">
                    Ongoing
                </div>
            </div>
        </div>   
           
      </div>
    </div>
  )
}

export const Assignees1 = ({assignee})=>{
  const [isActive2, setIsActive2] = useState(false);

  const { search, setSearch, filteredData } = useSearch([], ["title"]);
  const total = assignee.noOfDays * assignee.noOfHours;

  const allMembers = [
  {
      "id": "390",
      "firstname": "anushka.op9@gmail.com",
      "lastname": null
  },
  {
      "id": "318",
      "firstname": "Mathieu",
      "lastname": "f"
  },
  {
      "id": "317",
      "firstname": "Kumail",
      "lastname": "A"
  },
  {
      "id": "325",
      "firstname": "xosuwome",
      "lastname": "finews"
  },
  {
      "id": "531",
      "firstname": "pe",
      "lastname": "coka"
  },
  {
      "id": "242",
      "firstname": "sdfsd@sfdg.xom",
      "lastname": null
  },
  {
      "id": "246",
      "firstname": "ascas@srgs.com",
      "lastname": null
  },
]

  return (
      <div className="flex h-[40px] items-center self-stretch border-b border-[#DDE5ED] bg-[#F9FBFE]">

        <div className="flex p-[5px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
            <Popover
                open={isActive2}
                placement="bottomLeft"
                onOpenChange={(open) => {
                setSearch("");
                setIsActive2(open);
                }}
                content={
                    <div className="flex w-[280px] flex-col justify-center items-start gap-1">
                        <div className="flex w-[280px] flex-col items-start rounded-lg border border-[#DDE5ED] bg-[#F9FBFE] shadow-lg">
                          <div className="flex h-[275px] flex-col items-start self-stretch">
                              <div className="h-[307px] flex flex-col items-start self-stretch overflow-y-auto custom-scrollbar-project-bar">

                              {allMembers.map((assignee) => (
                                <div key={assignee.id} className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>

                                    <div className="text-gray-600 text-sm font-light leading-5">
                                      {assignee.firstname} {assignee.lastname}
                                    </div>
                                </div>
                              ))}


                                {/* <div className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>
                                    <div className="text-gray-600 text-sm font-light leading-5">
                                       Alvina Akhtar Ali
                                    </div>
                                </div>

                                <div className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>
                                    <div className="text-gray-600 text-sm font-light leading-5">
                                        Alvina Akhtar Ali
                                    </div>
                                </div>

                                <div className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>
                                    <div className="text-gray-600 text-sm font-light leading-5">
                                        Alvina Akhtar Ali
                                    </div>
                                </div>

                                <div className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>
                                    <div className="text-gray-600 text-sm font-light leading-5">
                                        Alvina Akhtar Ali
                                    </div>
                                </div>

                                <div className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>
                                    <div className="text-gray-600 text-sm font-light leading-5">
                                        Alvina Akhtar Ali
                                    </div>
                                </div>

                                <div className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>
                                    <div className="text-gray-600 text-sm font-light leading-5">
                                        Alvina Akhtar Ali
                                    </div>
                                </div>

                                <div className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>
                                    <div className="text-gray-600 text-sm font-light leading-5">
                                        Alvina Akhtar Ali
                                    </div>
                                </div>

                                <div className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE]">
                                    <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                        <div
                                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                            // style={{ backgroundImage: `url` }}
                                        >
                                        </div>  
                                    </div>
                                    <div className="text-gray-600 text-sm font-light leading-5">
                                        Alvina Akhtar Ali
                                    </div>
                                </div> */}

                              </div>
                          </div>
                        </div>
                        

                    </div>
                    }
                    arrow={false}
                    trigger={["click"]}
              >
                <span onClick={() => setIsActive2(!isActive2)}>
                    <div className="flex p-[2px_4px] items-center gap-[8px] flex-1">
                        <div
                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                            // style={{ backgroundImage: `url` }}
                        > 
                          {/* image to be added */}
                        </div> 

                          <p className="text-[#344054] text-sm font-medium leading-[20px] font-outfit">
                            {assignee.assignee_name.firstname} {assignee.assignee_name.lastname}
                          </p>

                          <ChevronIcon
                              className="cursor-pointer text-black"
                              style={{
                                  width:'16px',
                                  height:'16px',
                                  transition: "transform 0.3s ease-in-out 0s",
                                  transform: isActive2 ? "rotate(180deg)" : "rotate(0deg)",
                              }}
                          />
                    </div>
                </span>
              </Popover>
        </div>

        <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
            <div className="flex h-[28px] p-[4px_6px] items-center gap-[2px] flex-1 rounded-[4px]">
                <p className="text-[#667085] text-sm font-light leading-[20px] font-outfit">
                    {assignee ? assignee.noOfDays : 'Enter no. of days'}
                </p>
            </div>  
        </div>

        <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
            <div className="flex h-[28px] p-[4px_6px] items-center gap-[2px] flex-1 rounded-[4px]">
                <p className="text-[#667085] text-sm font-light leading-[20px] font-outfit">
                  {assignee ? assignee.noOfHours : 'Enter no. of hours'}
                </p>  
            </div>  
        </div>

        <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch">
            <p className="text-[#344054] text-sm font-medium leading-[20px] font-outfit">
                {total || '0'}
            </p>
            <div className="flex w-[14px] h-[14px] flex-col items-center gap-[4px]">
                <div className="flex w-[14px] h-[14px] p-[1.167px_1.75px] justify-center items-center flex-shrink-0">
                    <DeleteIcon
                        style={{
                            width: '10.5px',
                            height: '11.667px',
                            flexShrink: 0,
                            strokeWidth: '1px',
                            stroke: 'var(--Error-600, #D92D20)'
                        }}
                    />                      
                </div> 
            </div>    
        </div>
      </div>    
  )
}

export const Assignees = ({assignee, onDelete,onDaysChange, onHoursChange})=>{
  const [isActive2, setIsActive2] = useState(false);

  const { search, setSearch, filteredData } = useSearch([], ["title"]);

  const [days, setDays] = useState(assignee.noOfDays || 0);
  const [hours, setHours] = useState(assignee.noOfHours || 0);
  const [selectedAssignee, setSelectedAssignee] = useState(assignee.assignee_name);

  const allMembers = [
    {
        "id": "113",
        "firstname": "Damini",
        "lastname": "Kesharkar"
    },
    {
        "id": "114",
        "firstname": "Siddhant",
        "lastname": "Welinkar"
    },
    {
        "id": "115",
        "firstname": "Pradnya",
        "lastname": "Chaure"
    },
    {
        "id": "116",
        "firstname": "Ebrahim",
        "lastname": "Kothari"
    },
    {
        "id": "117",
        "firstname": "Kevin",
        "lastname": "Shah"
    },
    {
        "id": "118",
        "firstname": "Ganesh",
        "lastname": "M"
    },
    {
        "id": "119",
        "firstname": "Nutan",
        "lastname": "G"
    },
  ]

  const handleAssigneeSelect = (assignee) => {
    setSelectedAssignee(assignee);
    setIsActive2(false);
  };

  const displayName = selectedAssignee  && (selectedAssignee.firstname || selectedAssignee.lastname)
  ? `${selectedAssignee.firstname} ${selectedAssignee.lastname}`
  : (allMembers.length > 0)
      ? `${allMembers[0].firstname} ${allMembers[0].lastname}`
      : 'Add Assignee';

  const placeholderText = isActive2 ? 'Select Assignee' : displayName;

  const total = days * hours;

  return (
      <div className="flex h-[40px] items-center self-stretch border-b border-[#DDE5ED] bg-[#F9FBFE] hover:bg-[#F0F5FA] transition-colors group">
        <div className="w-full flex p-[5px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
          
            <Popover
                open={isActive2}
                placement="bottomLeft"
                onOpenChange={(open) => {
                setSearch("");
                setIsActive2(open);
                }}
                content={
                    <div className="flex w-[280px] flex-col justify-center items-start gap-1">
                        <div className="flex w-[280px] flex-col items-start rounded-lg border border-[#DDE5ED] bg-[#F9FBFE] shadow-lg">
                          <div className="flex h-[275px] flex-col items-start self-stretch">
                              <div className="h-[307px] flex flex-col items-start self-stretch overflow-y-auto custom-scrollbar-project-bar">

                                {allMembers.map((assignee) => (
                                  <div key={assignee.id} className="flex p-2 px-4 items-center gap-1.5 self-stretch bg-[#F9FBFE] cursor-pointer"
                                    onClick={() => handleAssigneeSelect(assignee)}
                                  >
                                      <div className="flex w-6 h-6 justify-center items-center rounded-full">
                                          <div
                                              className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                                              // style={{ backgroundImage: `url` }}
                                          >
                                          </div>  
                                      </div>

                                      <div className="text-gray-600 text-sm font-light leading-5">
                                        {assignee.firstname} {assignee.lastname}
                                      </div>
                                  </div>
                                ))}

                              </div>
                              <div className="flex w-[11px] h-[275px] p-[2px] items-end gap-[10px] absolute right-0">
                                <div className="flex flex-col items-center pb-[133.5px] flex-1 self-stretch">
                                    <div className="w-[6px] h-[133.5px] rounded-[8px] bg-gray-200">
                                        
                                    </div>
                                </div>
                              </div>
                          </div>
                        </div>
                    </div>
                    }
                    arrow={false}
                    trigger={["click"]}
              >
                <span onClick={() => setIsActive2(!isActive2)}>
                  {/* className="w-full h-full p-[4px_6px] text-[#667085] text-sm font-light leading-[20px] hover:bg-white font-outfit placeholder-[#667085] placeholder:text-sm placeholder:font-light placeholder:leading-[20px] placeholder:font-outfit rounded-[4px] border border-transparent focus:border-[#2E90FA] hover:border-[#D1D5DB] bg-transparent outline-none transition-all focus:outline-none hover:border-[#D1D5DB] focus:border-[#2E90FA] focus:ring-0 transition-colors cursor-pointer" */}
                    <div 
                      className={`flex p-[2px_4px] items-center gap-[8px] flex-1 rounded-[4px] border transition-colors cursor-pointer ${
                        isActive2
                          ? 'bg-white border-[#2E90FA]'
                          : 'hover:bg-white border-transparent hover:border-[#D1D5DB]'
                      }`}   
                    >
                        <div
                            className="flex w-[24px] h-[24px] flex-col items-center justify-center rounded-full bg-gray-300 bg-cover bg-center bg-no-repeat"
                            // style={{ backgroundImage: `url` }}
                        > 
                          {/* image to be added */}
                        </div> 

                          <span
                            className={`text-sm font-sm leading-5 ${isActive2 ? 'text-gray-400' : ''}`}
                          >
                            {placeholderText}
                          </span>                            

                          <ChevronIcon
                              className="cursor-pointer text-black"
                              style={{
                                  width:'16px',
                                  height:'16px',
                                  transition: "transform 0.3s ease-in-out 0s",
                                  transform: isActive2 ? "rotate(180deg)" : "rotate(0deg)",
                              }}
                          />
                    </div>
                </span>
            </Popover>
        </div>

        <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
          <div className="flex h-[28px] flex-1 rounded-[4px]">
              <input
                  type="number"
                  value={days || ''}
                  placeholder="Enter no. of days"
                  onChange={(e) => {
                    setDays(Number(e.target.value))
                    onDaysChange(assignee.id, e.target.value)
                  }}
                  className="w-full h-full p-[4px_6px] text-[#667085] text-sm font-medium leading-[20px] hover:bg-white font-outfit placeholder-[#667085] placeholder:text-sm placeholder:font-light placeholder:leading-[20px] placeholder:font-outfit rounded-[4px] border border-transparent focus:border-[#2E90FA] hover:border-[#D1D5DB] bg-transparent outline-none transition-all focus:outline-none hover:border-[#D1D5DB] focus:border-[#2E90FA] focus:ring-0 transition-colors cursor-pointer"
              />
          </div>
        </div>

        <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
            <div className="flex h-[28px] flex-1 rounded-[4px]">
                <input
                    type="number"
                    value={hours || ''}
                    placeholder="Enter no. of hours"
                    onChange={(e) => {
                      setHours(Number(e.target.value))
                      onHoursChange(assignee.id, e.target.value)
                    }}
                    className="w-full h-full p-[4px_6px] text-[#667085] text-sm font-medium leading-[20px] font-outfit hover:bg-white font-outfit placeholder-[#667085] placeholder:text-sm placeholder:font-light placeholder:leading-[20px] placeholder:font-outfit rounded-[4px] border border-transparent focus:border-[#2E90FA] hover:border-[#D1D5DB] bg-transparent outline-none transition-all focus:outline-none hover:border-[#D1D5DB] focus:border-[#2E90FA] focus:ring-0 transition-colors cursor-pointer"
                />
            </div>
        </div>

        <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch">
            <p className="w-full text-[#344054] text-sm font-medium leading-[20px] font-outfit">
                {total || '0'}
            </p>
            <div className="flex w-[14px] h-[14px] flex-col items-center gap-[4px] group-hover:block hidden"
              onClick={onDelete}
            >
              <DeleteIcon
                  className="text-red-600"
                  style={{
                      width: '14px',
                      height: '14px',
                      flexShrink: 0,
                      stroke: 'var(--Error-600, #D92D20)'
                  }}
                   
              />                     
            </div>    
        </div>
      </div>    
  )
}

export const DisplayWeeks = ({week, isLastWeek})=>{
  const [isActive3, setIsActive3] = useState(false);

    const weeks = [
        {
            "weekName": "Week 30",
            "startDate": "2024-07-25",
            "endDate": "2024-07-26"
        },
        {
            "weekName": "Week 31",
            "startDate": "2024-07-29",
            "endDate": "2024-08-02"
        },
        {
            "weekName": "Week 32",
            "startDate": "2024-08-05",
            "endDate": "2024-08-09"
        },
        {
            "weekName": "Week 33",
            "startDate": "2024-08-12",
            "endDate": "2024-08-16"
        },
        {
            "weekName": "Week 34",
            "startDate": "2024-08-19",
            "endDate": "2024-08-23"
        },
        {
            "weekName": "Week 35",
            "startDate": "2024-08-26",
            "endDate": "2024-08-30"
        }
    ]    

  const [isAssigneesVisible, setIsAssigneesVisible] = useState(isLastWeek); 
  const { search, setSearch, filteredData } = useSearch([], ["title"]);

  const [totalHours,setTotalHours] = useState(0);
  

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);

  const total = days * hours;

  const navigate = useNavigate();
  const [assignees, setAssignees] = useState(week.assignees || []);

  const [selectedWeek, setSelectedWeek] = useState(null);

  const handleWeekSelect = (week) => {
    setSelectedWeek(week);
    setIsActive3(false);
  };
  

  const [assigneesData, setAssigneesData] = useState(
    week.assignees.map(assignee => ({
      id: assignee.id,
      name: assignee.name,
      days: Number(assignee.noOfDays),
      hours: Number(assignee.noOfHours)
    }))
  );

  useEffect(() => {
    setTotalHours(calculateTotalHoursPerWeek());
  }, [assigneesData]);
 
  const handleDaysChange = (id, value) => {
    setAssigneesData(prevData =>
      prevData.map(assignee =>
        assignee.id === id ? { ...assignee, days: Number(value) } : assignee
      )
    );
  };

  const handleHoursChange = (id, value) => {
    setAssigneesData(prevData =>
      prevData.map(assignee =>
        assignee.id === id ? { ...assignee, hours: Number(value) } : assignee
      )
    );
  };
  

  const calculateTotalHoursPerWeek = () => {
    return assigneesData.reduce((total, assignee) => total + (assignee.days * assignee.hours), 0);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options); 
  };

  const addAssignee = () => {
    const newAssignee = {
      id: Date.now().toString(), 
      assigneeId: '',            
      noOfDays: '',
      noOfHours: '',
      assignee_name: {
        firstname: '',
        lastname: ''
      }
    };
    setAssignees([...assignees, newAssignee]);
  };

  const handleDeleteAssignee = (id) => {
    setAssignees((prevAssignees) => prevAssignees.filter(assignee => assignee.id !== id));
  };

  return (
    <div className="flex w-full flex-col items-start">
        <div className="flex h-[42px] p-[10px_24px] items-center gap-[8px] self-stretch border-b border-gray-200 bg-white"> 
          
          <Tooltip 
            title={"Project Week"} 
            placement="bottom"
            style= {{
              display: 'flex',
              padding: '4px 8px',
              flexDirection: 'column',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              borderRadius: '4px',
              background: '#000000', 
              color: '#ffffff',
            }}
          >
            <div className="flex w-[20px] h-[20px] justify-center items-center cursor-pointer">
                <div className="flex w-[18px] h-[18px] justify-end items-center">
                    <div className="flex px-[8px] py-0 justify-center items-center gap-[4px] rounded-full bg-[#165ABF] w-full h-full">
                        <div className="text-[#F9FBFE] text-center font-outfit text-xs font-medium leading-[18px]">
                            {week.id}
                        </div>
                    </div>  
                </div>  
            </div> 
          </Tooltip>

          {/* weeks  */}
          <Popover
            open={isActive3}
            placement="bottomLeft"
            onOpenChange={(open) => {
              setSearch("");
              setIsActive3(open);
            }}
            content={
              <div className="flex w-[280px] flex-col justify-center items-start gap-1">
                <div className="flex w-[280px]  flex-col items-start rounded-lg border border-[#DDE5ED] bg-[#F9FBFE] shadow-lg">
                  <div className="flex h-[307px] flex-col items-start self-stretch">
                      <div className="h-[307px] flex flex-col items-start self-stretch overflow-y-auto custom-scrollbar-project-bar">

                        {weeks.map((week, index) => (
                          <div key={index} 
                            // className="h-[51px] flex p-2.5 items-center gap-3 self-stretch border-b border-[#D1E9FF] bg-[#EFF8FF]"
                            className={`h-[51px] flex p-2.5 items-center gap-3 self-stretch border-b bg-white hover:bg-[#F0F5FA] transition-colors cursor-pointer`} 
                          >
                            <div className="flex flex-col justify-center items-start gap-0.5 flex-1">
                              <div className="text-gray-700 text-sm font-medium leading-5">
                                {week.weekName}
                              </div>
                              <div className="self-stretch text-gray-500 text-xs font-normal leading-normal font-outfit">
                                {formatDate(week.startDate)} - {formatDate(week.endDate)}
                              </div>
                            </div>
                          </div>
                        ))}

                      </div>
                  </div>
                  
                </div>
                
                <div className="flex w-[11px] h-[275px] p-[2px] items-end gap-[10px] absolute right-0">
                  <div className="flex flex-col items-center pb-[133.5px] flex-1 self-stretch">
                    <div className="w-[6px] h-[133.5px] rounded-[8px] bg-gray-200">
                      
                    </div>
                  </div>
                </div>

              </div>
            }
              arrow={false}
              trigger={["click"]}
          >
            <span onClick={() => setIsActive3(!isActive3)}>
                <div
                  className={`flex items-start rounded-[8px] ${isActive3 ? 'border border-blue-500 bg-transparent' : 'border border-transparent bg-transparent hover:bg-gray-100'}`}
                >
                  <div className="flex px-[10px] py-[5px] justify-center items-center gap-[8px] rounded-[4px]">
                    <div className="text-[#292927] text-sm font-medium leading-[20px] font-outfit">
                      {week.week}
                    </div>
                    <p className="text-[#667085] text-xs font-light leading-[18px] font-outfit">
                        {formatDate(week.startDate)} - {formatDate(week.endDate)}
                    </p>

                    <ChevronIcon
                        className="cursor-pointer text-black"
                        style={{
                            width:'20px',
                            height:'20px',
                            transition: "transform 0.3s ease-in-out 0s",
                            transform: isActive3 ? "rotate(180deg)" : "rotate(0deg)",
                        }}
                    />
                  </div>  
                </div>
            </span> 

          </Popover>

          
          {/* gap */}
          <div className="flex h-[20px] items-center gap-[8px] flex-1"></div>

          <div className="text-[#667085] text-[10px] font-normal font-outfit" style={{ fontFamily: 'Outfit', lineHeight: 'normal' }}>
            Added by Kumail Amiruddin Amiruddin on 13 August 2024, 05:30 pm
          </div>

          {!isAssigneesVisible && (<>
            <div className="flex p-1 px-2 items-center gap-2 rounded-[13px] bg-[#D1E9FF]">
              <div className="text-[#101828] text-center text-xs font-medium leading-[18px]" style={{ fontFamily: 'Outfit, sans-serif' }}>
                TOTAL
              </div>
              <div className="text-[#101828] text-center text-xs font-medium leading-[18px]" style={{ fontFamily: 'Outfit, sans-serif' }}>
                {totalHours}h
              </div>
            </div>
          </>)}

          {/* chevronButton */}
          <div className="flex items-start rounded-[8px]">
              <div className="flex p-[2px] justify-center items-center gap-[8px] rounded-[4px] border border-[#C0CBD7] bg-[#F9FBFE]">
                  <ChevronIcon
                      style={{
                          width:'20px',
                          height:'20px',
                          transition: "transform 0.5s ease-in-out",
                          transform: isAssigneesVisible ? "rotate(0deg)" : "rotate(180deg)",
                      }}
                    onClick={() => setIsAssigneesVisible(!isAssigneesVisible)} 
                  ></ChevronIcon> 
              </div>  
          </div>

        </div>

        {isAssigneesVisible && (<>

            {/* Header */}
            <div className="flex h-[24px] items-center self-stretch border-b border-[#DDE5ED] bg-[#F0F5FA]">
                <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
                    <p className="text-[#667085] text-xs text-center font-medium leading-[18px] font-outfit">
                        Assignee
                    </p>  
                </div>

                <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
                    <p className="text-[#667085] text-xs text-center font-medium leading-[18px] font-outfit">
                        No. of Days
                    </p>  
                </div>

                <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch border-r border-[#DDE5ED]">
                    <p className="text-[#667085] text-xs text-center font-medium leading-[18px] font-outfit">
                        No. of Hours/Day
                    </p>
                </div>

                <div className="flex p-[12px_24px] items-center gap-[12px] flex-1 self-stretch">
                    <p className="text-[#667085] text-xs text-center font-medium leading-[18px] font-outfit">
                        Total hours
                    </p>  
                </div>

            </div>

            {assignees.map((assignee) => (
              <Assignees key={assignee.id} assignee={assignee}  onDelete={() => handleDeleteAssignee(assignee.id)}  onDaysChange={(value) => handleDaysChange(assignee.id, value)} onHoursChange={(value) => handleHoursChange(assignee.id, value)}/>
            ))}
              
            {/* Add Assignee */}
            <div className="flex h-[32px] items-center self-stretch bg-[#F9FBFE] ">
                <div className="flex h-[36px] p-[12px] px-[24px] items-center gap-[12px] hover:text-black flex-1 cursor-pointer" onClick={addAssignee}>
                    <div className="flex items-start gap-[12px]  hover:text-black">
                        <PlusIconBoldGray
                            style={{
                                width:'20px',
                                height:'20px'
                            }}
                        >
                        </PlusIconBoldGray>
                        <p className="text-[#667085] font-outfit text-sm font-medium leading-5 hover:text-black">
                            Add Assignee
                        </p> 
                    </div>  
                </div>  
            </div>

            {/* Total */}
            <div className="flex w-full h-[24px] items-center self-stretch bg-[#D1E9FF]">

                <div className="flex w-[1000px] py-[5px] px-[24px] items-center gap-[12px] self-stretch">
                    <div className="flex w-[818px] py-[2px] px-[4px] items-center gap-[8px] flex-1">
                        <div 
                            className="text-[#101828] text-[12px] font-medium leading-[18px]" 
                            style={{ fontFamily: 'Outfit, sans-serif' }}
                        >
                            TOTAL
                        </div>
                    </div>  
                </div>

                <div className="flex w-[243px] h-[36px] py-[12px] px-[24px] items-center gap-[12px] flex-1">
                    <div 
                        className="text-[#101828] text-[14px] font-medium leading-[20px] text-center" 
                        style={{ fontFamily: 'Outfit, sans-serif' }}
                    >
                      {calculateTotalHoursPerWeek()}
                    </div>
                </div>

            </div>

        </>)}  
    </div>
  )

}
function moment(date: any, arg1: string): any {
  throw new Error("Function not implemented.");
}

