import React, { useMemo, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { schemeCategory10 } from "d3-scale-chromatic";
import { SettingIcon } from "../../Core/svgV2/SettingsIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { Popover, Checkbox, Divider, Tooltip } from "antd";
import { useTooltip } from "@nivo/tooltip";
import { DeleteScheduleIcon } from "../../Core/svgV3/DeleteIcon";

const CustomGroupedBarChart = ({
  data,
  keys,
  indexByX = "project",
  indexByY = "Numbers",
  colorsScheme = schemeCategory10,
  heading = "Team time on projects",
  tooltip = "Test",
  showTotalValues = false,
  Total1Text = "Total 1",
  Total2Text = "Total 2",
  weeks = 1,
  heightKey = 21,
}) => {
  const [selectedKeys, setSelectedKeys] = useState(keys);

  const colorMapping = useMemo(() => {
    const colorScale =
      indexByY === "Numbers" ? ["#D9D6FE", "#7A5AF8"] : ["#D1E9FF", "#2E90FA"];

    return keys.reduce((acc, key, i) => {
      acc[key] = colorScale[i % colorScale.length];
      return acc;
    }, {} as Record<string, string>);
  }, [keys, indexByY]);

  const CustomBarComponent = ({ bar, shouldShift }) => {
    const { x, y, width, height, color, data } = bar;

    const { tasks, bugs } = data.data; // Extract Tasks and Bugs from bar data
    console.log(bar, "group bar", tasks, bugs);

    // Calculate the ratio if both keys are selected
    const showRatio =
      selectedKeys.includes("tasks") && selectedKeys.includes("bugs");
    const ratio = showRatio && tasks && bugs ? (tasks / bugs).toFixed(2) : null;

    // Adjust the width and x positioning
    const adjustedWidth = width * 0.65;
    const adjustedX = shouldShift
      ? x + ((width - adjustedWidth) / 2 - 13)
      : x + (width - adjustedWidth) / 2;

    const cornerRadius = 6; // Radius for the rounded top corners

    // SVG path for a bar with rounded top corners
    const path = `
      M${adjustedX},${y + cornerRadius} 
      a${cornerRadius},${cornerRadius} 0 0 1 ${cornerRadius},-${cornerRadius} 
      h${adjustedWidth - 2 * cornerRadius} 
      a${cornerRadius},${cornerRadius} 0 0 1 ${cornerRadius},${cornerRadius} 
      v${height - cornerRadius}
      h-${adjustedWidth} 
      z
    `;

    // Tooltip hook from Nivo
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const handleMouseEnter = (event) => {
      const tooltipText =
        indexByY === "Numbers" ? `No. of ${data.id}:` : `Time on ${data.id}:`;

      showTooltipFromEvent(
        <div className="shadow-xl rounded-lg bg-white p-2">
          <span
            style={{
              display: "inline-block",
              width: "10px",
              height: "10px",
              backgroundColor: color,
              borderRadius: "50%",
              marginRight: "5px",
            }}
          />
          {tooltipText}{" "}
          <strong className="ml-0.5">
            {data.value}
            {indexByY !== "Numbers" ? "h" : ""}
          </strong>
        </div>,
        event
      );
    };

    const handleMouseLeave = () => {
      hideTooltip();
    };

    return (
      <g>
        <path
          d={path}
          fill={color}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseEnter} // Ensure tooltip follows mouse movement
          onMouseLeave={handleMouseLeave}
        />
        {/* Display the number inside the bar and rotate it by 90 degrees */}
        <text
          x={adjustedX + adjustedWidth / 2}
          y={y + height / 2 + 4}
          fill="white"
          fontSize="12"
          textAnchor="middle"
          transform={`rotate(-90, ${adjustedX + adjustedWidth / 2}, ${
            y + height / 2
          })`}
          className="cursor-default"
        >
          {data.value}
        </text>

        {/* Conditionally display the ratio if both "Tasks" and "Bugs" are selected */}
        {showRatio && shouldShift && (
          <text
            x={adjustedX + adjustedWidth / 2 + 10}
            y={y - 20} // Position above the bar
            fill="black"
            fontSize="12"
            textAnchor="middle"
            transform={`rotate(-90, ${adjustedX + adjustedWidth / 2}, ${
              y - 10
            })`} // Rotate text by 90 degrees
          >
            {ratio}
          </text>
        )}
      </g>
    );
  };

  const CustomLayer = ({ bars }) => {
    const halfLength = Math.floor(bars.length / 2);
    return (
      <>
        {bars.map((bar, i) => (
          <CustomBarComponent
            key={bar.key}
            bar={bar}
            shouldShift={i >= halfLength}
          />
        ))}
      </>
    );
  };

  const handleKeyChange = (key) => {
    // If the key is already selected, remove it; otherwise, add it
    if (selectedKeys.includes(key)) {
      // Remove the key from selectedKeys
      setSelectedKeys(
        selectedKeys.filter((selectedKey) => selectedKey !== key)
      );
    } else {
      // Add the key to selectedKeys
      setSelectedKeys([...selectedKeys, key]);
    }
  };

  // Filtered data based on selected keys
  const filteredData = data.map((item) => {
    const filteredItem = {};
    filteredItem[indexByX] = item[indexByX];
    selectedKeys.forEach((key) => {
      filteredItem[key] = item[key];
    });
    return filteredItem;
  });

  // Total calculations for the selected keys
  const total1Key = keys[0]; // Assume Total1 corresponds to the first key
  const total2Key = keys[1]; // Assume Total2 corresponds to the second key

  const total1 = selectedKeys.includes(total1Key)
    ? filteredData.reduce((sum, item) => sum + (item[total1Key] || 0), 0)
    : 0;

  const total2 = selectedKeys.includes(total2Key)
    ? filteredData.reduce((sum, item) => sum + (item[total2Key] || 0), 0)
    : 0;

  const ratio = total1 && total2 ? (total1 / total2).toFixed(2) : null;

  const popoverContent = (
    <div>
      <div className="flex justify-center py-1 px-2">Display Setting</div>
      <div className="flex flex-col gap-[0.5px]">
        {keys?.map((key) => (
          <div
            key={key}
            className={`py-1 px-2 ${
              selectedKeys.includes(key) ? "bg-blue-100" : "hover:bg-gray-100"
            }`}
          >
            <Checkbox
              checked={selectedKeys.includes(key)}
              onChange={() => handleKeyChange(key)}
            >
              {key}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );

  const popoverContentThreeDots = (
    <div className="p-1 flex gap-2 items-center hover:bg-gray-200 cursor-pointer rounded">
      <div>
        <DeleteScheduleIcon className="text-error-600" />
      </div>
      <div className="text-error-600">Delete Chart</div>
    </div>
  );

  const maxValue = weeks * 50; // Example: 1 week = 50, 2 weeks = 100, and so on.

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-xl">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div>{heading}</div>
          <Tooltip
            title={<div className="">{tooltip}</div>}
            placement={"right"}
          >
            <div>
              {/* Tooltip info icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="none"
                viewBox="0 0 12 12"
              >
                <path
                  stroke="#667085"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 8V6m0-2h.005M11 6A5 5 0 111 6a5 5 0 0110 0z"
                ></path>
              </svg>
            </div>
          </Tooltip>
        </div>

        <div className="flex">
          {/* Popover with checkboxes for key selection */}
          <Popover
            content={popoverContent}
            trigger="click"
            placement="bottom"
            arrow={false}
          >
            <SettingIcon className="text-black cursor-pointer" />
          </Popover>
          <Popover
            content={popoverContentThreeDots}
            trigger="click"
            placement="bottom"
            arrow={false}
          >
            <DotsVerticalIcon className="text-gray-500" />
          </Popover>
        </div>
      </div>

      {/* Show totals if selected keys exist */}
      {showTotalValues && (
        <div className="flex items-center gap-4">
          {selectedKeys.includes(total1Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase">
                {Total1Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                {total1}
                {indexByY === "Hours" && (
                  <span className="out-500-14 text-gray-500">h</span>
                )}
              </div>
            </div>
          )}
          {selectedKeys.includes(total1Key) &&
            selectedKeys.includes(total2Key) && (
              <Divider
                type="vertical"
                style={{ height: "40px", margin: "0" }}
              />
            )}
          {selectedKeys.includes(total2Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase">
                {Total2Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                {total2}
                {indexByY === "Hours" && (
                  <span className="out-500-14 text-gray-500">h</span>
                )}
              </div>
            </div>
          )}
          {/* Show ratio only if both Total1 and Total2 are visible */}
          {selectedKeys.includes(total1Key) &&
            selectedKeys.includes(total2Key) && (
              <>
                <Divider
                  type="vertical"
                  style={{ height: "40px", margin: "0" }}
                />
                <div className="flex flex-col gap-1">
                  <div className="out-500-10 text-gray-400 uppercase">
                    Ratio
                  </div>
                  <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                    {ratio}
                  </div>
                </div>
              </>
            )}
        </div>
      )}

      {/* Render the bar chart */}
      <div style={{ height: "400px", width: "100%" }}>
        <ResponsiveBar
          data={filteredData}
          keys={selectedKeys}
          indexBy={indexByX}
          margin={{ top: 10, right: 0, bottom: 80, left: 50 }}
          padding={0.3}
          innerPadding={0}
          borderWidth={0}
          groupMode="grouped"
          colors={({ id }) => colorMapping[id]}
          borderRadius={0}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: -30,
            legend: indexByX,
            legendPosition: "middle",
            legendOffset: 43,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: indexByY,
            legendPosition: "middle",
            legendOffset: -40,
            tickValues: Array.from(
              { length: maxValue / 10 + 1 },
              (_, i) => i * 10
            ), // Dynamic range increments of 10
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor="white"
          legends={[]}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 10,
                  fill: "#555",
                },
              },
            },
            grid: {
              line: {
                stroke: "#ccc",
                strokeWidth: 1,
                strokeDasharray: "4 4",
              },
            },
          }}
          enableGridY={true}
          gridYValues={Array.from(
            { length: maxValue / 10 + 1 },
            (_, i) => i * 10
          )}
          minValue={0}
          maxValue={maxValue}
          animate={true}
          layers={[
            "grid",
            "axes",
            "markers",
            "legends",
            CustomLayer, // Custom layer with custom bar adjustments
          ]}
        />
      </div>
      <div className="flex gap-4 bg-gray-50 rounded items-center justify-center flex-grow py-3">
        {Object.keys(colorMapping)?.map((key) => (
          <div
            key={key}
            onClick={() => {
              // Toggle the key selection
              if (selectedKeys.includes(key)) {
                setSelectedKeys(
                  selectedKeys.filter((selectedKey) => selectedKey !== key)
                );
              } else {
                setSelectedKeys([...selectedKeys, key]);
              }
            }}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              marginBottom: "2px",
              cursor: "pointer",
              opacity: selectedKeys.includes(key) ? 1 : 0.5, // Grey out if not selected
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: colorMapping[key],
                borderRadius: "50%",
              }}
            />
            <span>{key}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomGroupedBarChart;
