import React, { useState } from "react";
import ReportsLayout from "./ReportsLayout";
import ReportsNavBar from "./ReportsNavBar";
import AllChartsScreen from "./AllChartsScreen";
import CompareTrendsScreen from "./CompareTrendsScreen";

const ReportsScreenV3 = () => {
  // State to track which screen is visible (1 or 2)
  const [screen, setScreen] = useState(1);
  const selectedCharts = [
    {
      type: 1,
      title: "Total Time Estimated vs Time Spent",
      indexByX: "project",
      indexByY: "Hours",
      toolTip:
        "Compare the estimated time with the actual time spent on tasks to assess project performance during the selected weeks(s).",
      chartType: "CustomBulletChart",
      keys: ["Time estimated", "Time spent"],
      data: [
        {
          project: "Website Redesign",
          "Time estimated": 17,
          "Time spent": 16,
        },
        {
          project: "Mobile App",
          "Time estimated": 24,
          "Time spent": 25,
        },
      ],
    },
    {
      type: 2,
      title: "Team Time on Projects",
      indexByX: "project",
      indexByY: "Hours",
      toolTip:
        "View the time spent by each team on different projects during the selected weeks(s).",
      chartType: "DividedBarChart",
      data: [
        {
          project: "Website Redesign",
          "Design Team": 3,
          "Dev Team": 20,
          "QA Team": 2,
          "Business Team": 2,
        },
        {
          project: "Mobile App",
          "Design Team": 4,
          "Dev Team": 8,
          "QA Team": 35,
        },
        {
          project: "API Development",
          "Design Team": 15,
          "Dev Team": 10,
          "QA Team": 4,
        },
        {
          project: "E-commerce Platform",
          "Design Team": 25,
          "Dev Team": 6,
          "QA Team": 5,
        },
        {
          project: "Machine Learning Model",
          "Design Team": 5,
          "Dev Team": 12,
          "QA Team": 6,
        },
      ],
      keys: ["Design Team", "Dev Team", "QA Team", "Business Team"],
    },
    {
      type: 3,
      title: "Task vs Bug Ratio",
      indexByX: "project",
      indexByY: "Numbers",
      toolTip:
        "View the ratio of the number of tasks to bugs within projects during the selected weeks(s).",
      chartType: "GroupedBarChart",
      data: [
        { project: "Antarctic Website", tasks: 10, bugs: 20 },
        { project: "Website", tasks: 11, bugs: 25 },
        { project: "Pebble", tasks: 24, bugs: 35 },
        { project: "PROJECT V2", tasks: 10, bugs: 18 },
        { project: "HHS", tasks: 12, bugs: 15 },
      ],
      keys: ["tasks", "bugs"],
    },
    {
      type: 4,
      title: "Task vs Bug Time Ratio",
      indexByX: "project",
      indexByY: "Hours",
      toolTip:
        "View the ratio of time spent on tasks to time spent on bugs within projects during the selected weeks(s).",
      chartType: "GroupedBarChart",
      data: [
        { project: "Antarctic Website", tasks: 15, bugs: 18 },
        { project: "Website", tasks: 24, bugs: 26 },
        { project: "Pebble", tasks: 17, bugs: 28 },
        { project: "PROJECT V2", tasks: 13, bugs: 21 },
        { project: "HHS", tasks: 16, bugs: 25 },
      ],
      keys: ["tasks", "bugs"],
    },
    {
      type: 5,
      title: "Team Allocation",
      indexByX: "project",
      indexByY: "Members",
      toolTip:
        "View the allocation of teams on different projects during the selected week(s).",
      chartType: "DividedBarChart",
      data: [
        {
          project: "Website Redesign",
          "Design Team": 5,
          "Dev Team": 20,
          "QA Team": 10,
          "Business Team": 5,
        },
        {
          project: "Mobile App",
          "Design Team": 8,
          "Dev Team": 10,
          "QA Team": 15,
        },
      ],
      keys: ["Design Team", "Dev Team", "QA Team", "Business Team"],
    },
    {
      type: 6,
      title: "Total Time Spent by Teams",
      indexByX: "team",
      indexByY: "Hours",
      toolTip:
        "View the total time spent by each assignee within teams during the selected week(s).",
      chartType: "DividedBarChart",
      data: [
        {
          team: "Design",
          Alice: 10,
          Bob: 15,
          Charlie: 8,
        },
        {
          team: "Development",
          David: 12,
          Eve: 18,
          Frank: 16,
        },
        {
          team: "QA",
          Grace: 14,
          Heidi: 9,
          Ivan: 11,
        },
      ],
      keys: [
        "Alice",
        "Bob",
        "Charlie",
        "David",
        "Eve",
        "Frank",
        "Grace",
        "Heidi",
        "Ivan",
      ],
    },
    {
      type: 7,
      title: "Total Time Estimated vs Time Spent (Tasks)",
      indexByX: "task",
      indexByY: "Hours",
      toolTip:
        "Compare the estimated time with the actual time spent on tasks to assess project performance during the selected weeks(s).",
      chartType: "CustomBulletChart",
      keys: ["Time estimated", "Time spent"],
      data: [
        {
          task: "Task A",
          "Time estimated": 20,
          "Time spent": 16,
        },
        {
          task: "Task B",
          "Time estimated": 30,
          "Time spent": 20,
        },
      ],
    },
    {
      type: 8,
      title: "Assignee Time on Projects",
      indexByX: "assignee",
      indexByY: "Hours",
      toolTip:
        "View the time spent by each assignee on different projects during the selected week(s).",
      chartType: "DividedBarChart",
      data: [
        {
          assignee: "Alice",
          "Website Redesign": 10,
          "API Development": 40,
        },
        {
          assignee: "Bob",
          "Website Redesign": 15,
          "API Development": 5,
        },
        {
          assignee: "Charlie",
          "Website Redesign": 30,
          "API Development": 10,
        },
      ],
      keys: ["Website Redesign", "API Development"],
    },
    {
      type: 9,
      title: "Task vs Bug Ratio",
      indexByX: "assignee",
      indexByY: "Numbers",
      toolTip:
        "View the ratio of the number of tasks to bugs for each assignee during the selected weeks(s).",
      chartType: "GroupedBarChart",
      data: [
        { assignee: "Alice", tasks: 15, bugs: 18 },
        { assignee: "Bob", tasks: 24, bugs: 26 },
        { assignee: "Charlie", tasks: 17, bugs: 28 },
        { assignee: "David", tasks: 13, bugs: 21 },
        { assignee: "Eve", tasks: 16, bugs: 25 },
      ],
      keys: ["tasks", "bugs"],
    },
    {
      type: 10,
      title: "Task vs Bug Time Ratio",
      indexByX: "assignee",
      indexByY: "Hours",
      toolTip:
        "View the ratio of time spent on tasks to time spent on bugs for each assignee during the selected weeks(s).",
      chartType: "GroupedBarChart",
      data: [
        { assignee: "Alice", tasks: 15, bugs: 25 },
        { assignee: "Bob", tasks: 8, bugs: 12 },
        { assignee: "Charlie", tasks: 5, bugs: 9 },
        { assignee: "David", tasks: 6, bugs: 8 },
        { assignee: "Dan", tasks: 11, bugs: 15 },
      ],
      keys: ["tasks", "bugs"],
    },
  ];

  return (
    <ReportsLayout>
      <ReportsNavBar screen={screen} setScreen={setScreen} />
      {screen === 1 && <AllChartsScreen selectedCharts={selectedCharts} />}
      {screen === 2 && <CompareTrendsScreen />}
    </ReportsLayout>
  );
};

export default ReportsScreenV3;
