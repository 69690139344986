import React, { useState } from "react";
import { ResponsiveBar, BarItemProps } from "@nivo/bar";
import { useTooltip } from "@nivo/tooltip";
import { SettingIcon } from "../../Core/svgV2/SettingsIcon";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { Popover, Checkbox, Divider, Tooltip } from "antd";
import {
  DeleteCalendarIcon,
  DeleteIcon,
  DeleteScheduleIcon,
} from "../../Core/svgV3/DeleteIcon";

type BarDatum = {
  id: string;
  "Time estimated": number;
  "Time spent": number;
};

// Custom Bar Component
const CustomBar: React.FC<
  BarItemProps<BarDatum> & { selectedKeys: string[] }
> = ({ bar, selectedKeys }) => {
  const { "Time estimated": estimatedTime, "Time spent": actualTime } =
    bar.data.data;

  const bulletY = bar.y + (1 - actualTime / estimatedTime) * bar.height; // Bullet (actual time) position
  const highestPointY = Math.min(bulletY, bar.y); // Calculate the highest point (either bar or bullet)

  const barColor = "#D1E9FF"; // Light blue for the bar
  const bulletColor = "#2E90FA"; // Dark blue for the bullet

  // Calculate the percentage difference
  const percentageDifference =
    ((estimatedTime - actualTime) / estimatedTime) * 100;

  // Tooltip hook from Nivo
  const { showTooltipFromEvent, hideTooltip } = useTooltip();

  const handleMouseEnter = (event: React.MouseEvent<SVGElement>) => {
    showTooltipFromEvent(
      <div className="shadow-xl rounded-lg bg-white p-2">
        {/* Conditionally show "Time estimated" */}
        {selectedKeys.includes("Time estimated") && (
          <>
            {/* Circle before the label */}
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: "#D1E9FF", // Light blue for Time Estimated
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            Time estimated: <strong className="ml-0.5">{estimatedTime}h</strong>
            <br />
          </>
        )}
        {/* Conditionally show "Time spent" */}
        {selectedKeys.includes("Time spent") && (
          <>
            {/* Circle before the label */}
            <span
              style={{
                display: "inline-block",
                width: "10px",
                height: "10px",
                backgroundColor: "#2E90FA", // Dark blue for Time Spent
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            Time spent:<strong className="ml-0.5">{actualTime}h</strong>
          </>
        )}
      </div>,
      event // Ensure event is passed for mouse following
    );
  };

  const handleMouseLeave = () => {
    hideTooltip();
  };

  return (
    <g>
      {/* Conditionally render the bar for estimated time */}
      {selectedKeys.includes("Time estimated") && (
        <rect
          x={bar.x + bar.width * 0.29} // Adjust x position to center the narrower bar
          y={bar.y}
          width={bar.width * 0.4} // Reduce the width to 40%
          height={bar.height}
          fill={barColor}
          rx={5}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseEnter} // Ensure tooltip moves with the mouse
          onMouseLeave={handleMouseLeave}
        />
      )}

      {/* Conditionally render the line and bullet for actual time */}
      {selectedKeys.includes("Time spent") && (
        <>
          {/* Line from the bullet (dot) to the bottom */}
          <line
            x1={bar.x + bar.width / 2}
            y1={bulletY}
            x2={bar.x + bar.width / 2}
            y2={bar.y + bar.height}
            stroke={bulletColor}
            strokeWidth={2}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseEnter} // Ensure tooltip moves with the mouse
            onMouseLeave={handleMouseLeave}
          />

          {/* Bullet for actual time */}
          <circle
            cx={bar.x + bar.width / 2}
            cy={bulletY}
            r={5}
            fill={bulletColor}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseEnter} // Ensure tooltip moves with the mouse
            onMouseLeave={handleMouseLeave}
          />
        </>
      )}

      {/* Conditionally display the percentage difference or the time */}
      {selectedKeys.includes("Time estimated") &&
      selectedKeys.includes("Time spent") ? (
        <text
          x={bar.x + bar.width / 2}
          y={highestPointY - 27} // Position it above the highest point
          textAnchor="middle"
          fill="#000"
          transform={`rotate(-90, ${bar.x + bar.width / 2}, ${
            highestPointY - 30
          })`}
        >
          {percentageDifference.toFixed(0)}%
        </text>
      ) : selectedKeys.includes("Time estimated") ? (
        <text
          x={bar.x + bar.width / 2}
          y={highestPointY - 30} // Position it above the highest point
          textAnchor="middle"
          fill="#000"
          transform={`rotate(-90, ${bar.x + bar.width / 2}, ${
            highestPointY - 30
          })`} // Rotate text by 90 degrees
        >
          {estimatedTime}h
        </text>
      ) : selectedKeys.includes("Time spent") ? (
        <text
          x={bar.x + bar.width / 2}
          y={bulletY - 30} // Position it above the bullet point
          textAnchor="middle"
          fill="#000"
          transform={`rotate(-90, ${bar.x + bar.width / 2}, ${bulletY - 30})`} // Rotate text by 90 degrees
        >
          {actualTime}h
        </text>
      ) : null}
    </g>
  );
};

// Custom Bar Chart Component
const CustomBarChart: React.FC<{
  data: BarDatum[];
  selectedKeys: string[];
  weeks: number;
  indexByX: string;
  indexByY: string;
}> = ({ data, selectedKeys, weeks, indexByX, indexByY }) => {
  // Calculate the max range based on the `weeks` prop
  const maxValue = weeks * 50; // Example: 1 week = 50, 2 weeks = 100, and so on.

  return (
    <div style={{ height: 400, width: "100%" }}>
      <ResponsiveBar
        data={data}
        keys={["Time estimated"]}
        indexBy={indexByX}
        margin={{ top: 10, right: 0, bottom: 80, left: 50 }}
        padding={0.3}
        layout="vertical"
        barComponent={(props) => (
          <CustomBar {...props} selectedKeys={selectedKeys} />
        )}
        colors={{ scheme: "nivo" }}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: -30,
          legend: indexByX,
          legendPosition: "middle",
          legendOffset: 70,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: indexByY,
          legendPosition: "middle",
          legendOffset: -40,
          tickValues: Array.from(
            { length: maxValue / 10 + 1 },
            (_, i) => i * 10
          ), // Dynamic range increments of 10
        }}
        minValue={0}
        maxValue={maxValue} // Set the dynamic maximum value
        enableGridX={false} // Disable vertical grid lines
        enableGridY={true} // Enable horizontal grid lines
        gridYValues={Array.from(
          { length: maxValue / 10 + 1 },
          (_, i) => i * 10
        )} // Ensure grid lines are plotted at increments of 10
        animate={true}
        theme={{
          grid: {
            line: {
              stroke: "#ccc", // Set the color for grid lines
              strokeWidth: 1,
              strokeDasharray: "4 4", // Make the grid lines dotted
            },
          },
        }}
      />
    </div>
  );
};

const colorMapping = {
  "Time estimated": "#D1E9FF",
  "Time spent": "#2E90FA",
};

const CustomBulletChart = ({
  data,
  keys,
  indexByX = "project",
  indexByY = "Hours",
  heading = "Team time on projects",
  tooltip = "Test",
  showTotalValues = false,
  Total1Text = "Total Time Estimated",
  Total2Text = "Total Time Spent",
  heightKey = 21,
  weeks = 1,
}) => {
  const [selectedKeys, setSelectedKeys] = useState([
    "Time estimated",
    "Time spent",
  ]);
  console.log(indexByX, "indexByX", data);
  const handleKeyChange = (key) => {
    // If the key is already selected, remove it; otherwise, add it
    if (selectedKeys.includes(key)) {
      setSelectedKeys(
        selectedKeys.filter((selectedKey) => selectedKey !== key)
      );
    } else {
      setSelectedKeys([...selectedKeys, key]);
    }
  };

  const total1Key = "Time estimated";
  const total2Key = "Time spent";

  const total1 = selectedKeys.includes(total1Key)
    ? data.reduce((sum, item) => sum + (item[total1Key] || 0), 0)
    : 0;

  const total2 = selectedKeys.includes(total2Key)
    ? data.reduce((sum, item) => sum + (item[total2Key] || 0), 0)
    : 0;

  const ratio = total1 && total2 ? (total1 / total2).toFixed(2) : null;

  const popoverContent = (
    <div>
      <div className="flex justify-center py-1 px-2">Display Setting</div>
      <div className="flex flex-col gap-[0.5px]">
        {keys?.map((key) => (
          <div
            key={key}
            className={`py-1 px-2 ${
              selectedKeys.includes(key) ? "bg-blue-100" : "hover:bg-gray-100"
            }`}
          >
            <Checkbox
              checked={selectedKeys.includes(key)}
              onChange={() => handleKeyChange(key)}
            >
              {key}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  );

  const popoverContentThreeDots = (
    <div className="p-1 flex gap-2 items-center justify-start hover:bg-gray-200 cursor-pointer rounded p-2 ">
      <div>
        <DeleteScheduleIcon className="text-error-600" />
      </div>
      <div className="text-error-600">Delete Chart</div>
    </div>
  );

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-xl">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div className="out-500-16 text-black">{heading}</div>
          <Tooltip
            title={<div className="">{tooltip}</div>}
            placement={"right"}
          >
            <div>
              <svg
                xmlns="http://www.www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="none"
                viewBox="0 0 12 12"
              >
                <path
                  stroke="#667085"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 8V6m0-2h.005M11 6A5 5 0 111 6a5 5 0 0110 0z"
                ></path>
              </svg>
            </div>
          </Tooltip>
        </div>

        <div className="flex">
          <Popover
            content={popoverContent}
            trigger="click"
            placement="bottom"
            arrow={false}
          >
            <SettingIcon className="text-black cursor-pointer" />
          </Popover>
          <Popover
            content={popoverContentThreeDots}
            trigger="click"
            placement="bottom"
            arrow={false}
          >
            <DotsVerticalIcon className="text-gray-500" />
          </Popover>
        </div>
      </div>

      {/* Show totals if selected keys exist */}
      {showTotalValues && (
        <div className="flex items-center gap-4">
          {selectedKeys.includes(total1Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase">
                {Total1Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                {total1}
                <span className="out-500-14 text-gray-500">h</span>
              </div>
            </div>
          )}
          {selectedKeys.includes(total1Key) &&
            selectedKeys.includes(total2Key) && (
              <Divider
                type="vertical"
                style={{ height: "40px", margin: "0" }}
              />
            )}
          {selectedKeys.includes(total2Key) && (
            <div className="flex flex-col gap-1">
              <div className="out-500-10 text-gray-400 uppercase">
                {Total2Text}
              </div>
              <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px]">
                {total2}
                <span className="out-500-14 text-gray-500">h</span>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Render CustomBarChart with selected keys */}
      <CustomBarChart
        data={data}
        selectedKeys={selectedKeys}
        weeks={weeks}
        indexByX={indexByX}
        indexByY={indexByY}
      />
      <div className="flex gap-4 bg-gray-50 rounded items-center justify-center flex-grow py-3 h-[${heightKey}px] items-center">
        {Object.keys(colorMapping)?.map((key) => (
          <div
            key={key}
            onClick={() => {
              // Toggle the key selection
              if (selectedKeys.includes(key)) {
                setSelectedKeys(
                  selectedKeys.filter((selectedKey) => selectedKey !== key)
                );
              } else {
                setSelectedKeys([...selectedKeys, key]);
              }
            }}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              marginBottom: "2px",
              cursor: "pointer",
              opacity: selectedKeys.includes(key) ? 1 : 0.5, // Grey out if not selected
            }}
          >
            <div
              style={{
                width: "12px",
                height: "12px",
                backgroundColor: colorMapping[key],
                borderRadius: "50%",
              }}
            />
            <span>{key}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomBulletChart;
