import React, { useState } from "react";
import { Dropdown, Divider, Popover } from "antd";
import { ChevronIcon } from "../../Core/svgV2/Chevron"; // Ensure this is correctly imported
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import CustomBadge from "../../Core/CommonV2/CustomBadge";
import { HideIcon } from "../../Core/svgV3/HideIcon";
import { ReportsArrowIcon } from "../../Core/svgV3/ReportsArrowIcon";
import { assigneesData } from "../../Core/constants";

// Restructured data for assignees (same as before)

const weeksData = [
  { week: "Week 27", dates: "01 Jul - 07 Jul" },
  { week: "Week 28", dates: "08 Jul - 14 Jul" },
  { week: "Week 29", dates: "15 Jul - 21 Jul" },
  // Add more weeks as needed
];

function getDatesForWeek(selectedWeek) {
  const weekEntry = weeksData.find((entry) => entry.week === selectedWeek);
  return weekEntry ? weekEntry.dates : "NA";
}

const DataField = ({ label, value, isBugField, previousValue, showArrow }) => {
  const getIconRotationAndColor = (currentValue, previousValue) => {
    if (currentValue && previousValue) {
      if (currentValue > previousValue) {
        return {
          rotation: "rotate-180", // Arrow pointing down for increase
          color: "text-error-700", // Red for increase
        };
      } else {
        return {
          rotation: "", // No rotation for decrease
          color: "text-green-700", // Green for decrease
        };
      }
    }
    return { rotation: "", color: "" }; // Default state
  };

  const { rotation, color } = showArrow
    ? getIconRotationAndColor(value, previousValue)
    : { rotation: "", color: "" };

  return (
    <div className="flex justify-between">
      <div>{label}:</div>
      <div className="flex items-center">
        {showArrow && (
          <ReportsArrowIcon className={`${rotation} ${color} w-4 h-4 mr-1`} />
        )}
        <div>{value}</div>
      </div>
    </div>
  );
};

const PriorityBar = ({ data }) => {
  if (!data.priorityDistribution || data.totalBugs === 0) return null;

  const popoverContent = (color, priorityType, value) => (
    <div className="shadow-xl rounded-lg bg-white p-2 text-center">
      <span
        style={{
          display: "inline-block",
          width: "10px",
          height: "10px",
          backgroundColor: color,
          borderRadius: "50%",
          marginRight: "5px",
        }}
      />
      {priorityType} priority: <strong className="ml-0.5">{value}h</strong>
    </div>
  );

  return (
    <div className="w-full bg-gray-200 rounded h-2 mt-1 relative overflow-hidden">
      {/* Low Priority */}
      <Popover
        content={popoverContent(
          "#D1D5DB",
          "Low",
          data.priorityDistribution.low
        )}
        trigger="hover"
        placement="bottom"
      >
        <div
          className="absolute top-0 left-0 h-2 rounded-l-full bg-gray-300"
          style={{
            width: `${(data.priorityDistribution.low / data.totalBugs) * 100}%`,
          }}
        ></div>
      </Popover>

      {/* Medium Priority */}
      <Popover
        content={popoverContent(
          "#3B82F6",
          "Medium",
          data.priorityDistribution.medium
        )}
        trigger="hover"
        placement="bottom"
      >
        <div
          className="absolute top-0 left-0 h-2 bg-blue-500"
          style={{
            width: `${
              (data.priorityDistribution.medium / data.totalBugs) * 100
            }%`,
            marginLeft: `${
              (data.priorityDistribution.low / data.totalBugs) * 100
            }%`,
          }}
        ></div>
      </Popover>

      {/* High Priority */}
      <Popover
        content={popoverContent(
          "#EF4444",
          "High",
          data.priorityDistribution.high
        )}
        trigger="hover"
        placement="bottom"
      >
        <div
          className="absolute top-0 left-0 h-2 rounded-r-full bg-red-600"
          style={{
            width: `${
              (data.priorityDistribution.high / data.totalBugs) * 100
            }%`,
            marginLeft: `${
              (data.priorityDistribution.low / data.totalBugs) * 100 +
              (data.priorityDistribution.medium / data.totalBugs) * 100
            }%`,
          }}
        ></div>
      </Popover>
    </div>
  );
};

const CompareTrendsScreen = () => {
  const [selectedWeeks, setSelectedWeeks] = useState([
    "Week 29",
    "Week 28",
    "Week 27",
  ]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [searchTerms, setSearchTerms] = useState(["", "", ""]);
  const [visibleAssignees, setVisibleAssignees] = useState(
    Object.keys(assigneesData)
  );

  const handleWeekChange = (weekIndex, newWeek) => {
    const updatedWeeks = [...selectedWeeks];
    updatedWeeks[weekIndex] = newWeek;
    setSelectedWeeks(updatedWeeks);
    setOpenDropdownIndex(null); // Close the dropdown after selection
  };

  const handleSearchChange = (weekIndex, value) => {
    const updatedSearchTerms = [...searchTerms];
    updatedSearchTerms[weekIndex] = value;
    setSearchTerms(updatedSearchTerms);
  };

  const getFilteredWeeks = (weekIndex) => {
    const search = searchTerms[weekIndex].toLowerCase();
    return weeksData.filter(
      (weekData) =>
        weekData.week.toLowerCase().includes(search) ||
        weekData.dates.toLowerCase().includes(search)
    );
  };

  const handleAssigneeVisibility = (assignee) => {
    setVisibleAssignees((prev) => prev.filter((name) => name !== assignee));
  };

  return (
    <div className="">
      {/* Table header */}
      <div className="flex">
        <div className="out-500-16 p-3 border border-gray-200 min-w-[185px] bg-gray-50">
          Assignees
        </div>
        {selectedWeeks.map((week, index) => (
          <div
            key={index}
            className="flex w-[390px] justify-center text-center py-2.5 border border-gray-200 bg-gray-25"
          >
            <Dropdown
              overlay={
                <div
                  className="min-w-[300px] max-h-[50vh] overflow-auto shadow-xl rounded-lg p-2 bg-white"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* Custom Search Box */}
                  <CustomSearchBox
                    search={searchTerms[index]}
                    setSearch={(value) => handleSearchChange(index, value)}
                    placeholder="Search weeks or dates"
                    className="w-full mb-2"
                  />

                  {/* Week Options */}
                  <div>
                    {getFilteredWeeks(index).map((weekData) => (
                      <div
                        key={weekData.week}
                        className={`flex justify-between items-center mb-1 p-2 rounded cursor-pointer ${
                          selectedWeeks[index] === weekData.week
                            ? "bg-blue-100"
                            : "hover:bg-gray-100"
                        }`}
                        onClick={() => handleWeekChange(index, weekData.week)}
                      >
                        <div>
                          <div className="font-medium">{weekData.week}</div>
                          <div className="text-gray-500 text-xs">
                            {weekData.dates}
                          </div>
                        </div>

                        {/* Optional Badge for specific weeks */}
                        {weekData.week === "Week 29" && (
                          <div>
                            <CustomBadge
                              color={"#165ABF"}
                              text={"Current"}
                              className="bg-primary-100"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              }
              trigger={["click"]}
              open={openDropdownIndex === index}
              onOpenChange={(open) => {
                if (open) {
                  setOpenDropdownIndex(index); // Open the clicked dropdown
                } else {
                  setOpenDropdownIndex(null); // Close if clicked again
                }
              }}
              placement="bottom"
            >
              <div
                className={`flex gap-x-1.5 items-center pl-[14px] pr-[10px] py-1 rounded-[4px] cursor-pointer ${
                  openDropdownIndex === index
                    ? index === 0
                      ? "bg-primary-50"
                      : "bg-gray-50"
                    : ""
                }`}
              >
                <p
                  className={`out-500-14 ${
                    0 === index
                      ? index === 0
                        ? "text-primary-600"
                        : "text-gray-50"
                      : ""
                  }`}
                >
                  {selectedWeeks[index]}
                </p>
                <p
                  className={`out-500-12 ${
                    0 === index
                      ? index === 0
                        ? "text-primary-600"
                        : "text-gray-50"
                      : ""
                  }`}
                >
                  ({getDatesForWeek(selectedWeeks[index])})
                </p>
                <ChevronIcon
                  className={`${
                    openDropdownIndex === index ? "rotate-180" : ""
                  } ${
                    0 === index
                      ? index === 0
                        ? "text-primary-600"
                        : "text-gray-50"
                      : ""
                  }`}
                />
              </div>
            </Dropdown>
          </div>
        ))}
      </div>

      {/* Assignee data rows */}
      <div
        className="max-h-[calc(100vh-160px)]"
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {visibleAssignees.map((assigneeName, index) => {
          const assignee = assigneesData[assigneeName];
          return (
            <div key={index} className="flex group">
              {/* Assignee column */}
              <div className="flex items-center justify-between out-500-16 px-3 border border-gray-200 min-w-[185px] bg-gray-50 ">
                <div className="flex gap-2">
                  <img
                    src={assignee.avatar}
                    alt={`${assigneeName}'s avatar`}
                    className="w-6 h-6 rounded-lg mr-2"
                  />
                  <span>{assigneeName}</span>
                </div>
                <div
                  className=" opacity-0 group-hover:opacity-100 cursor-pointer"
                  onClick={() => handleAssigneeVisibility(assigneeName)}
                >
                  <HideIcon className="text-gray-700" />
                </div>
              </div>

              {/* Week data columns */}
              {selectedWeeks.map((week, weekIndex) => {
                const data = assignee.data[week];
                const previousData =
                  weekIndex > 0
                    ? assignee.data[selectedWeeks[weekIndex - 1]]
                    : null;

                return (
                  <div
                    key={weekIndex}
                    className="flex flex-col w-[390px] justify-center text-center border border-gray-200 bg-[#fcfdff] group-hover:bg-grey-50"
                  >
                    <div className="p-2">
                      {/* Use DataField component for each field */}
                      <DataField
                        label="Time estimated"
                        value={data.timeEstimated}
                        isBugField={false}
                        previousValue={
                          previousData ? previousData.timeEstimated : null
                        }
                        showArrow={previousData ? true : false}
                      />
                      <DataField
                        label="Time spent"
                        value={data.timeSpent}
                        isBugField={false}
                        previousValue={
                          previousData ? previousData.timeSpent : null
                        }
                        showArrow={previousData ? true : false}
                      />
                    </div>
                    <Divider className="my-0" />
                    <div className="p-2">
                      {/* Time on bugs with arrow comparison */}
                      <DataField
                        label="Total time on bugs"
                        value={data.timeOnBugs}
                        previousValue={
                          previousData ? previousData.timeOnBugs : null
                        }
                        showArrow={weekIndex !== 0}
                        isBugField={previousData ? true : false} // Assuming this is a bug-related field
                      />
                      <DataField
                        label="Total no. of bugs"
                        value={data.totalBugs}
                        isBugField={true} // Assuming this is a bug-related field
                        previousValue={null}
                        showArrow={false}
                      />

                      <PriorityBar data={data} />
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CompareTrendsScreen;
