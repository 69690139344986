export const GOAL_TYPE = {
  TASK: 1,
  EVENT: 2,
  BUG: 3,
  SUBTASK: 4,
};

export const PROJECT_TYPE = {
  SoftwareDevelopment: 1,
  DesignCreativity: 2,
  BusinessOperations: 3,
  ResearchDevelopment: 4,
  Other: 5,
};
export const TASK_ACTIVITY_LOG_ACTIONS = {
  ASSIGNEE_CHANGED: "ASSIGNEE_CHANGED",
  REPORTER_CHANGED: "REPORTER_CHANGED",
  STATUS_CHANGED: "STATUS_CHANGED",
  PHASE_CHANGED: "PHASE_CHANGED",
  PRIORITY_CHANGE: "PRIORITY_CHANGE",
  LABEL_CHANGE: "LABEL_CHANGE",
  SPRINT_CHANGE: "SPRINT_CHANGE",
  ESTIMATE_CHANGE: "ESTIMATE_CHANGE",
  TIMELOG_CHANGE: "TIMELOG_CHANGE",
  RELEASE_VERSION_CHANGE: "RELEASE_VERSION_CHANGE",
};

export const REACTIONS = [
  { emoji: "😊", reaction: "happy" },
  { emoji: "😆", reaction: "laugh" },
  { emoji: "❤️", reaction: "love" },
  { emoji: "👍", reaction: "thumbsUp" },
  { emoji: "👎", reaction: "thumbsDown" },
  { emoji: "👌", reaction: "ok" },
];

export const DEFAULT_LIMIT = 10;
export const DEFAULT_OFFSET = 0;

export const labelColors = [
  {
    id: 1,
    baseColor: "#EAEFE8",
    textColor: "#2B6419",
  },
  {
    id: 2,
    baseColor: "#F9FFF7",
    textColor: "#3E802E",
  },
  {
    id: 3,
    baseColor: "#F6FDF8",
    textColor: "#327A4B",
  },
  {
    id: 4,
    baseColor: "#F3F7F2",
    textColor: "#237F0A",
  },
  {
    id: 5,
    baseColor: "#EAEFF4",
    textColor: "#2B6495",
  },
  {
    id: 6,
    baseColor: "#F6FCFF",
    textColor: "#0F70A8",
  },
  {
    id: 7,
    baseColor: "#EAEFF9",
    textColor: "#2B64C5",
  },
  {
    id: 8,
    baseColor: "#E5E5FE",
    textColor: "#0000F5",
  },
  {
    id: 9,
    baseColor: "#EDE7F9",
    textColor: "#480DC4",
  },
  {
    id: 10,
    baseColor: "#FAF7FF",
    textColor: "#8D3AF6",
  },
  {
    id: 11,
    baseColor: "#FBF3FF",
    textColor: "#AD00FF",
  },
  {
    id: 12,
    baseColor: "#FEF4FF",
    textColor: "#BB27C5",
  },
  {
    id: 13,
    baseColor: "#FFF7FF",
    textColor: "#5D0E62",
  },
  {
    id: 14,
    baseColor: "#FFF5FC",
    textColor: "#C527A2",
  },
  {
    id: 15,
    baseColor: "#FAEAF1",
    textColor: "#B90751",
  },
  {
    id: 16,
    baseColor: "#FFF1F0",
    textColor: "#CF3629",
  },
  {
    id: 17,
    baseColor: "#F4EBE8",
    textColor: "#8D3A16",
  },
  {
    id: 18,
    baseColor: "#F8ECE8",
    textColor: "#BC401E",
  },
  {
    id: 19,
    baseColor: "#FFFAED",
    textColor: "#AB7B00",
  },
  {
    id: 20,
    baseColor: "#FEFAEC",
    textColor: "#574714",
  },
  {
    id: 21,
    baseColor: "#EAEAEA",
    textColor: "#232222",
  },
];

export const CalendarColors = [
  { id: 0, fill: "#AADAFF", stroke: "#165ABF" },
  { id: 1, fill: "#A6F4C5", stroke: "#039855" },
  { id: 2, fill: "#FFBCCE", stroke: "#F92B63" },
  { id: 3, fill: "#D9D6FE", stroke: "#6938EF" },
  { id: 4, fill: "#FCCEEE", stroke: "#DD2590" },
  { id: 5, fill: "#FDDCAB", stroke: "#EC4A0A" },
];

export const HourArray = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const DayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const tips = [
  "Before uploading to Waddle’s library, compress large folders to save data.",
  "Consider this, could a chat function or quick update on your tasks suffice instead of uploading a new file?",
  "Practice regular review and deletion of outdated or unnecessary files from Library and save those MBs.",
  "After uploading files in Library, remove them from your device to avoid redundant copies.",
  "Instead of downloading entire videos or audio files, use Waddle’s built-in player in Messages.",
  "When uploading or downloading multiple files, utilize batch actions to minimize individual file transfers.",
  "If using video conferencing, encourage shorter meetings and presentations to reduce data usage.",
  "Disable unnecessary features you're not using, like automatic syncing or desktop notifications.",
  "Don't keep Waddle open in multiple tabs or windows if you are not actively using them.",
  "Done for the day? Log out and close the application completely to avoid background processes.",
];

export const assigneesData = {
  Ashay: {
    avatar: "https://via.placeholder.com/50",
    data: {
      "Week 27": {
        timeEstimated: "40h 15m",
        timeSpent: "39h 24m",
        timeOnBugs: "25h 25m",
        totalBugs: 36,
        priorityDistribution: { high: 10, medium: 15, low: 11 },
      },
      "Week 28": {
        timeEstimated: "35h 20m",
        timeSpent: "34h 10m",
        timeOnBugs: "20h 30m",
        totalBugs: 30,
        priorityDistribution: { high: 8, medium: 12, low: 10 },
      },
      "Week 29": {
        timeEstimated: "50h 00m",
        timeSpent: "48h 45m",
        timeOnBugs: "30h 00m",
        totalBugs: 40,
        priorityDistribution: { high: 12, medium: 15, low: 13 },
      },
    },
  },
  Alvina: {
    avatar: "https://via.placeholder.com/50",
    data: {
      "Week 27": {
        timeEstimated: "45h 00m",
        timeSpent: "42h 00m",
        timeOnBugs: "28h 00m",
        totalBugs: 25,
        priorityDistribution: { high: 6, medium: 10, low: 9 },
      },
      "Week 28": {
        timeEstimated: "40h 00m",
        timeSpent: "39h 00m",
        timeOnBugs: "22h 00m",
        totalBugs: 20,
        priorityDistribution: { high: 5, medium: 8, low: 7 },
      },
      "Week 29": {
        timeEstimated: "48h 00m",
        timeSpent: "45h 00m",
        timeOnBugs: "25h 00m",
        totalBugs: 35,
        priorityDistribution: { high: 10, medium: 12, low: 13 },
      },
    },
  },
  Ravi: {
    avatar: "https://via.placeholder.com/50",
    data: {
      "Week 27": {
        timeEstimated: "38h 10m",
        timeSpent: "37h 05m",
        timeOnBugs: "22h 15m",
        totalBugs: 28,
        priorityDistribution: { high: 7, medium: 12, low: 9 },
      },
      "Week 28": {
        timeEstimated: "42h 25m",
        timeSpent: "40h 15m",
        timeOnBugs: "24h 30m",
        totalBugs: 32,
        priorityDistribution: { high: 9, medium: 14, low: 9 },
      },
      "Week 29": {
        timeEstimated: "46h 00m",
        timeSpent: "44h 30m",
        timeOnBugs: "26h 45m",
        totalBugs: 31,
        priorityDistribution: { high: 8, medium: 13, low: 10 },
      },
    },
  },
  Priya: {
    avatar: "https://via.placeholder.com/50",
    data: {
      "Week 27": {
        timeEstimated: "50h 00m",
        timeSpent: "48h 30m",
        timeOnBugs: "30h 00m",
        totalBugs: 40,
        priorityDistribution: { high: 12, medium: 15, low: 13 },
      },
      "Week 28": {
        timeEstimated: "47h 00m",
        timeSpent: "46h 00m",
        timeOnBugs: "27h 00m",
        totalBugs: 35,
        priorityDistribution: { high: 11, medium: 14, low: 10 },
      },
      "Week 29": {
        timeEstimated: "52h 00m",
        timeSpent: "51h 00m",
        timeOnBugs: "32h 00m",
        totalBugs: 45,
        priorityDistribution: { high: 15, medium: 18, low: 12 },
      },
    },
  },
  Jaya: {
    avatar: "https://via.placeholder.com/50",
    data: {
      "Week 27": {
        timeEstimated: "42h 00m",
        timeSpent: "40h 00m",
        timeOnBugs: "25h 00m",
        totalBugs: 30,
        priorityDistribution: { high: 8, medium: 12, low: 10 },
      },
      "Week 28": {
        timeEstimated: "41h 15m",
        timeSpent: "39h 45m",
        timeOnBugs: "23h 30m",
        totalBugs: 27,
        priorityDistribution: { high: 6, medium: 11, low: 10 },
      },
      "Week 29": {
        timeEstimated: "49h 00m",
        timeSpent: "47h 30m",
        timeOnBugs: "28h 15m",
        totalBugs: 33,
        priorityDistribution: { high: 10, medium: 14, low: 9 },
      },
    },
  },
  Maya: {
    avatar: "https://via.placeholder.com/50",
    data: {
      "Week 27": {
        timeEstimated: "30h 00m",
        timeSpent: "28h 45m",
        timeOnBugs: "15h 00m",
        totalBugs: 22,
        priorityDistribution: { high: 4, medium: 10, low: 8 },
      },
      "Week 28": {
        timeEstimated: "32h 15m",
        timeSpent: "30h 30m",
        timeOnBugs: "17h 00m",
        totalBugs: 18,
        priorityDistribution: { high: 3, medium: 9, low: 6 },
      },
      "Week 29": {
        timeEstimated: "35h 00m",
        timeSpent: "33h 00m",
        timeOnBugs: "20h 00m",
        totalBugs: 25,
        priorityDistribution: { high: 5, medium: 11, low: 9 },
      },
    },
  },
  Kiran: {
    avatar: "https://via.placeholder.com/50",
    data: {
      "Week 27": {
        timeEstimated: "55h 00m",
        timeSpent: "52h 00m",
        timeOnBugs: "35h 00m",
        totalBugs: 50,
        priorityDistribution: { high: 15, medium: 20, low: 15 },
      },
      "Week 28": {
        timeEstimated: "53h 30m",
        timeSpent: "50h 00m",
        timeOnBugs: "32h 00m",
        totalBugs: 45,
        priorityDistribution: { high: 12, medium: 22, low: 11 },
      },
      "Week 29": {
        timeEstimated: "60h 00m",
        timeSpent: "58h 00m",
        timeOnBugs: "40h 00m",
        totalBugs: 55,
        priorityDistribution: { high: 18, medium: 25, low: 12 },
      },
    },
  },
  Neha: {
    avatar: "https://via.placeholder.com/50",
    data: {
      "Week 27": {
        timeEstimated: "40h 30m",
        timeSpent: "38h 00m",
        timeOnBugs: "22h 00m",
        totalBugs: 29,
        priorityDistribution: { high: 7, medium: 13, low: 9 },
      },
      "Week 28": {
        timeEstimated: "39h 45m",
        timeSpent: "37h 30m",
        timeOnBugs: "21h 30m",
        totalBugs: 28,
        priorityDistribution: { high: 6, medium: 12, low: 10 },
      },
      "Week 29": {
        timeEstimated: "42h 15m",
        timeSpent: "40h 00m",
        timeOnBugs: "23h 15m",
        totalBugs: 31,
        priorityDistribution: { high: 8, medium: 14, low: 9 },
      },
    },
  },
};

export const REQUEST_ACKNOWLEDGED = 1;
export const ADDED_USER = 2;

// REQUEST STATUS
export const REQUEST_OPEN = "Open";
export const REQUEST_ACKNOWLEDGED_STATUS = "Acknowledged";
export const REQUEST_CLOSED_STATUS = "Closed";
