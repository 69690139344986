import React, { useState } from "react";
import TimeEstimatesLayout from "./TimeEstimatesLayout";
import TimeEstimatesMenu from "./TimeEstimatesMenu";

import { ProjectCount, ProjectContainer } from "./TimeEstimatesReusable";

const TimeEstimatesScreen = () => {

  const projects = [
    {
        "id": "506",
        "title": "Project Xd23",
        "projectImage": "",
        "startDate": "2024-04-08T02:19:36.132Z",
        "endDate": "2024-05-06T02:19:36.132Z"
    },
    {
        "id": "345",
        "title": "Project Xd",
        "projectImage": "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Request_2_lt4czqjx.svg-1709037821995.svg?download=1",
        "startDate": "2024-03-05T16:46:23.984Z",
        "endDate": "2024-08-15T16:46:23.984Z"
    },
    {
        "id": "498",
        "title": "asdfghjk",
        "projectImage": "",
        "startDate": "2024-04-30T03:20:04.559Z",
        "endDate": "2024-05-26T03:20:04.559Z"
    },
    {
        "id": "487",
        "title": "Final Schedule",
        "projectImage": "",
        "startDate": "2024-04-02T00:14:50.250Z",
        "endDate": "2024-05-07T00:14:50.250Z"
    },
    {
        "id": "516",
        "title": "abc xyz",
        "projectImage": "",
        "startDate": "2024-05-21T06:26:31.144Z",
        "endDate": "2024-06-30T06:26:31.144Z"
    },
    {
        "id": "507",
        "title": "sprint tst",
        "projectImage": "",
        "startDate": "2024-04-01T02:24:07.077Z",
        "endDate": "2024-05-20T02:24:07.077Z"
    }];

  const [search, setSearch] = useState('');
  const filteredProjects = projects.filter(project =>
    project.title.toLowerCase().includes(search.toLowerCase())
  );
  

  return (
    <TimeEstimatesLayout >
      <TimeEstimatesMenu  search={search} setSearch={setSearch} />
      <div className="min-h-[calc(100vh-174px)] max-h-[calc(100vh-174px)] bg-gray-50">

        <div className="flex flex-col items-start gap-[16px] px-[24px] py-[16px] w-[1165px] self-stretch">
          <ProjectCount count = {projects.length}/>

          <div className="flex flex-col items-start self-stretch">
            <div className="w-[1119px] flex flex-wrap gap-[16px] self-stretch items-start">
              
              {/* {projects.map(project => (
                  <div key={project.id} className="flex-shrink-0 w-[211px] h-[74px]">
                  <ProjectContainer project={project} />
                </div>
              ))} */}
              {filteredProjects.map(project => (
                <div key={project.id} className="flex-shrink-0 w-[211px] h-[74px]">
                  <ProjectContainer project={project} />
                </div>
              ))}

            </div>
          </div>

        </div>
      </div>

    </TimeEstimatesLayout>
  );
};

export default TimeEstimatesScreen;
