import React, { useState } from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";

const dummyTeams = ["development", "design", "quality assurance"];

const NewTeamModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const { deleteLabelModal } = useAppSelector((state) => state.schedule);

  const [teamName, setTeamName] = useState("");
  const [formState, setFormState] = useState({ errors: {} });

  const handleCreate = () => {
    const normalizedTeamName = teamName.trim().toLowerCase();

    if (dummyTeams.includes(normalizedTeamName)) {
      setFormState({
        errors: {
          teamName: { message: "Team already exists" },
        },
      });
    } else {
      setFormState({ errors: {} });
      // Proceed with the creation logic
      onClose();
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setTeamName(inputValue);

    const normalizedTeamName = inputValue.trim().toLowerCase();

    if (dummyTeams.includes(normalizedTeamName)) {
      setFormState({
        errors: {
          teamName: { message: "Team already exists" },
        },
      });
    } else {
      setFormState({ errors: {} });
    }
  };

  return (
    <CustomModal
      visible={visible}
      width="352px"
      onCancel={() => onClose()}
      body={
        <div className="px-4 pt-3 pb-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">New Team</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <div className="my-4">
            <CustomInputBox
              placeholder="Enter a name"
              value={teamName}
              onChange={handleInputChange}
              name="teamName"
              formState={formState} // Passing the formState with error handling
            />
          </div>
          <div className="flex mt-[10px] items-center gap-x-3 justify-end">
            <CustomButton
              text="Cancel"
              className="text-gray-500"
              height="30px"
              width="64px"
              onClick={() => onClose()}
            />
            <CustomButton
              text="Create"
              height="30px"
              className="text-primary-700"
              onClick={handleCreate}
            />
          </div>
        </div>
      }
    />
  );
};

export default NewTeamModal;
