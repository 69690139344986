import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { ChevronDoubleIcon } from "../../../Core/svgV2/Chevron";
import GoogleLogin from "react-google-login";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { ProjectStartDateEndDate } from "../TimeEstimatesReusable";
import FrostyTipOfTheDayIcon from "../../../Core/svgV3/FrostyTipOfTheDay";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../Core/redux/hooks";
import { updateDashboard } from "../../../Core/redux/slices/dashboard.slice";
import FrostyTImeEstimatesIcon from "../../../Core/svgV3/FrostyTimeEstimatesIcon";


const EstimatesMissingModal = ({ visible, onClose, onDiscard, title,description, buttonOneText, buttonTwoText}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    return (
        <CustomModal
        visible={visible}
        width="370px"
        onCancel={() => onClose()}
        body={
            <div className="inline-flex flex-col items-start gap-4 p-4 rounded-lg bg-white shadow-lg">

            <div className="flex h-5 justify-center items-start gap-[136px]"
                onClick={() => onClose()}
            >
                <div className="text-[#344054] text-sm font-medium leading-5 font-outfit">
                    {title}
                </div>
                
                <CrossIcon style={{
                    width:'20px',
                    height: '20px'
                }}/>

            </div>

            <FrostyTImeEstimatesIcon style={{
                width: '141px',
                height: '108px'
            }}></FrostyTImeEstimatesIcon>

            <div className="self-stretch text-[#667085] text-sm font-light leading-5">
                {description}
            </div>

            <div className="flex justify-end items-start gap-4 self-stretch">
                <div className="flex items-start rounded-md">
                    <div className="flex p-1.5 justify-center items-center gap-2 rounded-sm cursor-pointer"
                        onClick={() => {
                            onClose()
                            navigate(`/time-estimates/`)
                            dispatch(
                                updateDashboard({
                                  key: "alertPopupModal",
                                  value: {
                                    visible: true,
                                    data: {
                                      title: `Saved your time estimates`,
                                      subtitle: "",
                                      description: `Saved changes to Waddle`,
                                    },
                                  },
                                })
                              );
                        }}
                    >
                        <div className="text-[#667085] text-sm font-medium leading-5 font-outfit">
                            {buttonOneText}
                        </div>
                    </div>

                    <div className="flex items-start rounded-sm">

                        <div className="flex p-1.5 px-2.5 justify-center items-center gap-2 rounded border border-[#165ABF] bg-[#165ABF] shadow-xs cursor-pointer"
                            onClick={() => onClose()}
                        >
                            <div className="text-[#F9FBFE] text-sm font-medium leading-5 font-outfit">
                                {buttonTwoText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        }
        />
    )
};

export default EstimatesMissingModal;
