import React, { useState } from "react";
import { Dropdown, Checkbox, Avatar, Menu } from "antd";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import { CustomRouteButton } from "../Schedule/Timeline/TimelineMenu";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import CustomBadge from "../../Core/CommonV2/CustomBadge";
import CustomButton from "../../Core/CommonV2/CustomButton";
import ProjectsReportsModal from "../../ModulesV2/Schedule/Modals/ProjectsReportsModal";
import TeamsReportsModal from "../../ModulesV2/Schedule/Modals/TeamsReportsModal";
import AssigneesReportsModal from "../../ModulesV2/Schedule/Modals/AssigneesReportsModal";
import { RefreshIcon } from "../../Core/svgV3/RefreshIcon";
import ExportIcon from "../../Core/svgV3/ExportIcon";
import { ExportBoxIcon } from "../../Core/svgV3/ExportBoxIcon";

const weeksData = [
  { week: "Week 29", dates: "15 - 21 Jul 2024" },
  { week: "Week 30", dates: "22 - 28 Jul 2024" },
  // More week data...
];

const projectsData = [
  {
    id: 1,
    name: "Project Alpha",
    dates: "15 - 21 Jul 2024",
    status: "Ongoing",
    image: "https://via.placeholder.com/24",
  },
  {
    id: 2,
    name: "Project Beta",
    dates: "22 - 28 Jul 2024",
    status: "Upcoming",
    image: "https://via.placeholder.com/24",
  },
  // More projects...
];

const teamsData = [
  { id: 1, name: "Team Alpha", color: "#FF5733" },
  { id: 2, name: "Team Beta", color: "#33FF57" },
  // More teams...
];

const categoriesData = [
  {
    id: 1,
    name: "Time estimate",
    description: "Estimated time for the task",
  },
  {
    id: 2,
    name: "Time spent",
    description: "Actual time spent on the task",
  },
  {
    id: 3,
    name: "Time on bugs",
    description: "Time spent resolving bugs",
  },
  {
    id: 4,
    name: "Number of bugs",
    description: "Total bugs encountered during the task",
  },
];

const assigneesData = [
  {
    id: 1,
    name: "John Doe",
    image: "https://via.placeholder.com/24",
  },
  {
    id: 2,
    name: "Jane Smith",
    image: "https://via.placeholder.com/24",
  },
  // More assignees...
];

const ReportsNavBar = ({ screen, setScreen }) => {
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [search, setSearch] = useState("");
  const [dropdownOpenWeek, setDropdownOpenWeek] = useState(false);
  const [dropdownOpenProject, setDropdownOpenProject] = useState(false);
  const [dropdownOpenTeams, setDropdownOpenTeams] = useState(false);
  const [dropdownOpenAssignee, setDropdownOpenAssignee] = useState(false);
  const [dropdownOpenAddChart, setDropdownOpenAddChart] = useState(false);
  const [projectsReportsModal, setProjectsReportsModal] = useState(false);
  const [teamsReportsModal, setTeamsReportsModal] = useState(false);
  const [assigneesReportsModal, setAssigneesReportsModal] = useState(false);
  const [dropdownOpenCategory, setDropdownOpenCategory] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const filteredWeeks = weeksData?.filter(
    (weekData) =>
      weekData?.week?.toLowerCase()?.includes(search?.toLowerCase()) ||
      weekData?.dates?.toLowerCase()?.includes(search?.toLowerCase())
  );

  const filteredProjects = projectsData?.filter(
    (project) =>
      project?.name?.toLowerCase()?.includes(search.toLowerCase()) ||
      project?.dates?.toLowerCase()?.includes(search.toLowerCase())
  );

  const filteredTeams = teamsData?.filter((team) =>
    team?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const filteredCategories = categoriesData?.filter((category) =>
    category?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const filteredAssignees = assigneesData?.filter((assignee) =>
    assignee?.name?.toLowerCase()?.includes(search.toLowerCase())
  );

  const handleSelectChange = (list, setList, item) => {
    if (list?.includes(item)) {
      setList(list?.filter((selectedItem) => selectedItem !== item));
    } else {
      setList([...list, item]);
    }
  };

  const handleSelectAll = (list, setList, data, field) => (e) => {
    if (e.target.checked) {
      setList(data?.map((item) => item[field]));
    } else {
      setList([]);
    }
  };

  const getSelectedItemsText = (list, data, singleItemText) => {
    if (list?.length === 1) {
      const selectedItem = data.find((item) => item.name === list[0]);
      return (
        <div className="out-500-14 text-gray-700">
          {singleItemText}:{" "}
          <span className="out-300-12 text-gray-500">{selectedItem.name}</span>
        </div>
      );
    } else if (list?.length > 1) {
      return (
        <div className="out-500-14 text-gray-700">
          {list?.length} {singleItemText}s Selected
        </div>
      );
    }
    return (
      <div className="out-500-14 text-gray-700">Select {singleItemText}</div>
    );
  };

  const renderProjectsDropdown = () => (
    <div className="min-w-[330px] max-h-[350px] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search projects"
            className=""
          />
        </div>
        <div
          className={`py-2 px-4 ${
            selectedProjects?.length === projectsData?.length
              ? "bg-primary-50"
              : "hover:bg-gray-100"
          }`}
        >
          <Checkbox
            checked={selectedProjects?.length === projectsData?.length}
            onChange={handleSelectAll(
              selectedProjects,
              setSelectedProjects,
              projectsData,
              "name"
            )}
          >
            <div className="out-500-14 text-gray-600">Select All</div>
          </Checkbox>
        </div>
        {filteredProjects?.map((project) => {
          const isSelected = selectedProjects?.includes(project.name);
          return (
            <div
              key={project.id}
              className={`flex justify-between items-center py-2 px-4 cursor-pointer ${
                isSelected ? "bg-primary-50" : "hover:bg-gray-100"
              }`}
              onClick={() =>
                handleSelectChange(
                  selectedProjects,
                  setSelectedProjects,
                  project.name
                )
              }
            >
              <div className="flex gap-3 items-center">
                <Checkbox
                  checked={isSelected}
                  onChange={() =>
                    handleSelectChange(
                      selectedProjects,
                      setSelectedProjects,
                      project.name
                    )
                  }
                />
                <Avatar src={project.image} size={24} />
                <div className="text-gray-700">
                  {project.name}
                  <div className="text-gray-500">{project.dates}</div>
                </div>
              </div>
              <CustomBadge
                color={
                  project.status === "Ongoing"
                    ? "#165ABF"
                    : project.status === "Completed"
                    ? "#52c41a"
                    : "#999999"
                }
                text={project.status}
                className={`out-500-12 bg-${
                  project.status === "Ongoing"
                    ? "primary-100"
                    : project.status === "Completed"
                    ? "green-100"
                    : "gray-100"
                }`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderTeamsDropdown = () => (
    <div className=" min-w-[250px] max-h-[350px] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search teams"
            className=""
          />
        </div>
        <div
          className={`py-2 px-4 ${
            selectedTeams?.length === teamsData?.length
              ? "bg-primary-50"
              : "hover:bg-gray-100"
          }`}
        >
          <Checkbox
            checked={selectedTeams?.length === teamsData?.length}
            onChange={handleSelectAll(
              selectedTeams,
              setSelectedTeams,
              teamsData,
              "name"
            )}
          >
            <div className="out-300-14 text-gray-600">Select All</div>
          </Checkbox>
        </div>
        {filteredTeams?.map((team) => {
          const isSelected = selectedTeams?.includes(team.name);

          return (
            <div
              key={team.id}
              className={`flex gap-1.5 items-center py-2 px-4 cursor-pointer ${
                isSelected ? "bg-primary-50" : "hover:bg-gray-100"
              }`}
            >
              <Checkbox
                checked={selectedTeams?.includes(team.name)}
                onChange={() =>
                  handleSelectChange(selectedTeams, setSelectedTeams, team.name)
                }
              />
              <span
                className="w-2.5 h-2.5 rounded-full"
                style={{ backgroundColor: team.color }}
              />
              <div className="out-300-14 text-gray-500">{team.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderCategoriesDropdown = () => (
    <div className="max-h-[50vh] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search categories"
            className=""
          />
        </div>
        <div
          className={`py-2 px-4 ${
            selectedCategories?.length === categoriesData?.length
              ? "bg-primary-50"
              : "hover:bg-gray-100"
          }`}
        >
          <Checkbox
            checked={selectedCategories?.length === categoriesData?.length}
            onChange={handleSelectAll(
              selectedCategories,
              setSelectedCategories,
              categoriesData,
              "name"
            )}
          >
            <div className="out-300-14 text-gray-600">Select All</div>
          </Checkbox>
        </div>
        {filteredCategories?.map((category) => {
          const isSelected = selectedCategories?.includes(category.name);

          return (
            <div
              key={category.id}
              className={`flex gap-1.5 items-center py-2 px-4 cursor-pointer ${
                isSelected ? "bg-primary-50" : "hover:bg-gray-100"
              }`}
            >
              <Checkbox
                checked={selectedCategories?.includes(category.name)}
                onChange={() =>
                  handleSelectChange(
                    selectedCategories,
                    setSelectedCategories,
                    category.name
                  )
                }
              />
              <span className="out-300-14 text-gray-600">{category.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderAssigneesDropdown = () => (
    <div className=" max-h-[50vh] overflow-auto shadow-xl rounded-lg bg-white">
      <div onClick={(e) => e.stopPropagation()}>
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search assignees"
            className=""
          />
        </div>
        <div
          className={`py-2 px-4 ${
            selectedAssignees?.length === assigneesData?.length
              ? "bg-primary-50"
              : "hover:bg-gray-100"
          }`}
        >
          <Checkbox
            checked={selectedAssignees?.length === assigneesData?.length}
            onChange={handleSelectAll(
              selectedAssignees,
              setSelectedAssignees,
              assigneesData,
              "name"
            )}
          >
            <div className="out-300-14 text-gray-600">Select All</div>
          </Checkbox>
        </div>
        {filteredAssignees?.map((assignee) => {
          const isSelected = selectedAssignees?.includes(assignee.name);

          return (
            <div
              key={assignee.id}
              className={`flex gap-1.5 items-center py-2 px-4 cursor-pointer ${
                isSelected ? "bg-primary-50" : "hover:bg-gray-100"
              }`}
            >
              <Checkbox
                checked={selectedAssignees?.includes(assignee.name)}
                onChange={() =>
                  handleSelectChange(
                    selectedAssignees,
                    setSelectedAssignees,
                    assignee.name
                  )
                }
              />
              <Avatar src={assignee.image} size={24} />
              <div className="out-300-14 text-gray-600">{assignee.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );

  const renderAddChartDropdown = () => (
    <div className="shadow-xl rounded-lg bg-white">
      <div
        className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
        onClick={() => setProjectsReportsModal(true)}
      >
        <div>
          <div className="out-500-14 text-gray-700">Projects</div>
          <div className="out-500-10 text-gray-500">
            Overview of one or more projects
          </div>
        </div>
        <ChevronIcon className="-rotate-90 text-primary-600" />
      </div>
      <div
        className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
        onClick={() => setTeamsReportsModal(true)}
      >
        <div>
          <div className="out-500-14 text-gray-700">Teams</div>
          <div className="out-500-10 text-gray-500">
            Overview of one or more teams
          </div>
        </div>
        <ChevronIcon className="-rotate-90 text-primary-600" />
      </div>
      <div
        className="flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer"
        onClick={() => setAssigneesReportsModal(true)}
      >
        <div>
          <div className="out-500-14 text-gray-700">Assignees</div>
          <div className="out-500-10 text-gray-500">
            Overview of one or more assignees
          </div>
        </div>
        <ChevronIcon className="-rotate-90 text-primary-600" />
      </div>
    </div>
  );

  const getSelectedWeeksText = () => {
    if (selectedWeeks?.length === 1) {
      const selectedWeek = weeksData.find(
        (weekData) => weekData?.week === selectedWeeks[0]
      );
      return (
        <div className="out-500-14 text-gray-700">
          {selectedWeek?.week}{" "}
          <span className="out-300-12 text-gray-500 ml-0.5">
            ({selectedWeek.dates})
          </span>
        </div>
      );
    } else if (selectedWeeks?.length > 1) {
      return (
        <div className="out-500-14 text-gray-700">
          {selectedWeeks?.length} weeks selected
        </div>
      );
    }
    return <div className="out-500-14 text-gray-700">Select Weeks</div>;
  };

  const exportMenu = (
    <Menu>
      <Menu.Item key="1">
        <div className="flex gap-2 out-300-12">
          <ExportIcon className="text-gray-700 " />
          <div>Export as PNG</div>
        </div>
      </Menu.Item>
      <Menu.Item key="2">
        <div className="flex gap-2 out-300-12">
          <ExportIcon className="text-gray-700" />
          <div>Export as PDF</div>
        </div>
      </Menu.Item>
      <Menu.Item key="3">
        <div className="flex gap-2 out-300-12">
          <ExportIcon className="text-gray-700" />
          <div>Export as CSV</div>
        </div>
      </Menu.Item>
    </Menu>
  );

  const dropdownContentWeek = (
    <div className="min-w-[300px] max-h-[50vh] overflow-auto shadow-xl rounded-lg bg-white">
      {/* Prevent Dropdown from closing when clicking inside */}
      <div onClick={(e) => e.stopPropagation()}>
        {/* Custom Search Box */}
        <div className="p-4">
          <CustomSearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Search weeks"
            className=""
          />
        </div>
        {/* Select All Checkbox */}
        <div
          className={`py-2 px-4 ${
            selectedWeeks?.length === weeksData?.length
              ? "bg-primary-50"
              : "hover:bg-gray-100"
          }`}
        >
          <Checkbox
            checked={selectedWeeks?.length === weeksData?.length}
            onChange={handleSelectAll(
              selectedWeeks,
              setSelectedWeeks,
              weeksData,
              "week"
            )}
          >
            <div className="out-500-14 text-gray-600">Select All</div>
          </Checkbox>
        </div>
        {/* Week Options */}
        <div>
          {filteredWeeks?.map((weekData) => {
            const isSelected = selectedWeeks?.includes(weekData?.week);
            return (
              <div
                key={weekData?.week}
                className={`flex justify-between items-center py-2 px-4 cursor-pointer ${
                  isSelected ? "bg-primary-50" : "hover:bg-gray-100"
                }`}
                onClick={() =>
                  handleSelectChange(
                    selectedWeeks,
                    setSelectedWeeks,
                    weekData?.week
                  )
                }
              >
                <div className="flex gap-3">
                  <Checkbox
                    checked={isSelected}
                    onChange={() =>
                      handleSelectChange(
                        selectedWeeks,
                        setSelectedWeeks,
                        weekData?.week
                      )
                    }
                  />
                  <div className="out-500-14 text-gray-700">
                    {weekData?.week}
                    <div className="out-500-10 text-gray-500">
                      {weekData?.dates}
                    </div>
                  </div>
                </div>

                {weekData?.week === "Week 29" && (
                  <div>
                    <CustomBadge
                      color={"#165ABF"}
                      text={"Current"}
                      className="bg-primary-100"
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex justify-between px-6 py-3 bg-white items-center">
      {/* Route Buttons */}
      <div className="inline-flex bg-gray-50 rounded-[5px] gap-2 p-0.5">
        <CustomRouteButton
          title="All Charts"
          isSelected={screen === 1}
          onSelect={() => setScreen(1)}
        />
        <CustomRouteButton
          title="Compare Trends"
          isSelected={screen === 2}
          onSelect={() => setScreen(2)}
        />
      </div>

      {/* Dropdown for Weeks Selection */}
      <div className="flex gap-2 items-center">
        <div className="cursor-pointer">
          <RefreshIcon className="text-gray-700" />
        </div>
        {screen === 2 && (
          <Dropdown
            overlay={renderCategoriesDropdown}
            trigger={["click"]}
            open={dropdownOpenCategory}
            onOpenChange={setDropdownOpenCategory}
            placement="bottomRight"
          >
            <div className="flex gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer">
              <p className="out-500-14 text-gray-700">
                {getSelectedItemsText(
                  selectedCategories,
                  categoriesData,
                  "Category"
                )}
              </p>
              <ChevronIcon
                className={`text-gray-700 ${
                  dropdownOpenCategory ? "rotate-180" : ""
                }`}
              />
            </div>
          </Dropdown>
        )}
        {screen === 1 && (
          <Dropdown
            overlay={dropdownContentWeek}
            trigger={["click"]}
            open={dropdownOpenWeek}
            onOpenChange={setDropdownOpenWeek}
            placement="bottomRight"
          >
            <div className="flex gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer">
              <p className="out-500-14 text-gray-700">
                {getSelectedWeeksText()}
              </p>
              <ChevronIcon
                className={`text-gray-700 ${
                  dropdownOpenWeek ? "rotate-180" : ""
                }`}
              />
            </div>
          </Dropdown>
        )}
        <Dropdown
          overlay={renderProjectsDropdown}
          trigger={["click"]}
          open={dropdownOpenProject}
          onOpenChange={setDropdownOpenProject}
          placement="bottomRight"
        >
          <div className="flex gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer">
            <p className="out-500-14 text-gray-700">
              {getSelectedItemsText(selectedProjects, projectsData, "Project")}
            </p>
            <ChevronIcon
              className={`text-gray-700 ${
                dropdownOpenProject ? "rotate-180" : ""
              }`}
            />
          </div>
        </Dropdown>

        {screen === 1 && (
          <Dropdown
            overlay={renderTeamsDropdown}
            trigger={["click"]}
            open={dropdownOpenTeams}
            onOpenChange={setDropdownOpenTeams}
            placement="bottomRight"
          >
            <div className="flex gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer">
              <p className="out-500-14 text-gray-700">
                {getSelectedItemsText(selectedTeams, teamsData, "Team")}
              </p>
              <ChevronIcon
                className={`text-gray-700 ${
                  dropdownOpenTeams ? "rotate-180" : ""
                }`}
              />
            </div>
          </Dropdown>
        )}
        <Dropdown
          overlay={renderAssigneesDropdown}
          trigger={["click"]}
          open={dropdownOpenAssignee}
          onOpenChange={setDropdownOpenAssignee}
          placement="bottomRight"
        >
          <div className="flex gap-x-1.5 pl-[14px] pr-[10px] py-1 border border-grey-200 rounded-[4px] cursor-pointer">
            <p className="out-500-14 text-gray-700">
              {getSelectedItemsText(
                selectedAssignees,
                assigneesData,
                "Assignee"
              )}
            </p>
            <ChevronIcon
              className={`text-gray-700 ${
                dropdownOpenAssignee ? "rotate-180" : ""
              }`}
            />
          </div>
        </Dropdown>
        <Dropdown
          overlay={exportMenu}
          trigger={["click"]}
          placement="bottomRight"
        >
          <div className="out-500-14 text-gray-700 border border-grey-200 py-[5px] px-[10px] rounded-[4px] cursor-pointer">
            <div className="flex items-center">
              <div>Export</div>
              <ExportBoxIcon className="text-gray-700 ml-2" />
            </div>
          </div>
        </Dropdown>
        <Dropdown
          overlay={renderAddChartDropdown}
          trigger={["click"]}
          open={dropdownOpenAddChart}
          onOpenChange={setDropdownOpenAddChart}
          placement="bottomLeft"
        >
          <div className="flex gap-x-1.5 pl-[14px] pr-[10px] py-1 bg-primary-600 rounded-[4px] cursor-pointer">
            <p className="out-500-14 text-white">Add Chart</p>
            <ChevronIcon
              className={`text-white ${
                dropdownOpenAddChart ? "rotate-180" : ""
              }`}
            />
          </div>
        </Dropdown>
      </div>
      <ProjectsReportsModal
        visible={projectsReportsModal}
        onClose={() => setProjectsReportsModal(false)}
      />
      <TeamsReportsModal
        visible={teamsReportsModal}
        onClose={() => setTeamsReportsModal(false)}
      />
      <AssigneesReportsModal
        visible={assigneesReportsModal}
        onClose={() => setAssigneesReportsModal(false)}
      />
    </div>
  );
};

export default ReportsNavBar;
