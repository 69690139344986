import React, { useState } from "react";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import { useParams } from "react-router-dom";
import { Popover } from "antd";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import useSearch from "../../Core/hooks/useSearch";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { ProjectDropDown } from "./TimeEstimatesReusable";

const TimeEstimatesLayout = ({ children }) => {
  const { projectId } = useParams();
  const [isActive, setIsActive] = useState(false);
  // const projectList = [];
  const projectList = [
    {
        "id": "506",
        "title": "Project Xd23",
        "projectImage": "",
        "startDate": "2024-04-08T02:19:36.132Z",
        "endDate": "2024-05-06T02:19:36.132Z"
    },
    {
        "id": "345",
        "title": "Project Xd",
        "projectImage": "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Request_2_lt4czqjx.svg-1709037821995.svg?download=1",
        "startDate": "2024-03-05T16:46:23.984Z",
        "endDate": "2024-08-15T16:46:23.984Z"
    },
    {
        "id": "498",
        "title": "asdfghjk",
        "projectImage": "",
        "startDate": "2024-04-30T03:20:04.559Z",
        "endDate": "2024-05-26T03:20:04.559Z"
    },
    {
        "id": "487",
        "title": "Final Schedule",
        "projectImage": "",
        "startDate": "2024-04-02T00:14:50.250Z",
        "endDate": "2024-05-07T00:14:50.250Z"
    },
    {
        "id": "516",
        "title": "abc xyz",
        "projectImage": "",
        "startDate": "2024-05-21T06:26:31.144Z",
        "endDate": "2024-06-30T06:26:31.144Z"
    },
    {
        "id": "507",
        "title": "sprint tst",
        "projectImage": "",
        "startDate": "2024-04-01T02:24:07.077Z",
        "endDate": "2024-05-20T02:24:07.077Z"
    }];
  
    const [search, setSearch] = useState('');

    const filteredProjects = projectList.filter(project =>
        project.title.toLowerCase().includes(search.toLowerCase())
    );
  

  const getTitle = () => {
    let routeName = "";

    if (projectId) {
      const currentProject = projectList?.find((p) => p.id == projectId);
      if (currentProject?.title) {
        routeName = currentProject.title;
      }

      return routeName;
    } else {
      return "All Projects";
    }
  };
  const handleProjectSelect = () => {
    return setIsActive(false); 
  };

  return (
    <CustomLayout
      page="time-estimates"
      title={
        <div className="flex gap-x-1.5 ">
          <span className="out-300-14 text-gray-500">Time Estimates</span>{" "}
          <span className="out-300-14 text-gray-500">/</span>
          <span className="out-500-14 text-black">{getTitle()}</span>{" "}
          
          <Popover
            open={isActive}
            placement="bottom"
            onOpenChange={(open) => {
              setSearch("");
              setIsActive(open);
            }}
            content={
              <div className="w-[400px] shadow-md rounded-md bg-white border border-gray-100">

                <div className="p-2 px-4">
                  <CustomSearchBox placeholder="Search Projects" search={search} setSearch={setSearch} />
                </div>
              
                  <div className="max-h-[200px] overflow-y-auto">
                    {/* {filteredData.map((project) => (
                      <div
                        key={project.id}
                        onClick={() => {
                          setIsActive(false);
                          let screenName = "roadmap";
                          if (defaultNavigation.isEnabled) {
                            screenName = getScreenName(
                              defaultNavigation.screenId
                            );
                          }
                          const redirectTo =
                            +lastVisitedSchedulePage?.projectId === +project.id
                              ? lastVisitedSchedulePage?.redirectUrl
                              : project.currentactivesprint
                              ? `/schedule/${project.id}/${screenName}/${
                                  screenName == "sprints"
                                    ? project.currentactivesprint
                                    : ""
                                }`
                              : `/schedule/${project.id}/${screenName}`;
                          navigate(redirectTo);
                        }}
                        className={`w-full cursor-pointer hover:bg-gray-50 flex justify-between items-center py-2 px-4 ${
                          project.id === projectId ? "bg-gray-50" : ""
                        }`}
                      >
                        <div className="out-300-14 leading-5 text-gray-700">
                          {project.title}
                        </div>
                        {project.id === projectId ? (
                          <CheckIcon className="text-primary-600" />
                        ) : (
                          ""
                        )}
                      </div>
                    ))} */}
                  </div>

                  <div className="flex h-[255px]  flex-col items-start self-stretch">
                    <div className="w-full max-h-full overflow-y-auto">
                        {/* {projectList.map(project => (
                            <div key={project.id}>
                                <ProjectDropDown project={project} onSelect={handleProjectSelect} />
                            </div>
                        ))} */}

                        {filteredProjects.length ? (
                          filteredProjects.map(project => (
                            <ProjectDropDown 
                              key={project.id} 
                              project={project}
                              onSelect={() => setIsActive(false)}
                            />
                          ))
                        ) : (
                          <div className="p-4 text-center text-gray-500">No projects found</div>
                        )}

                    </div>
                    <div className="flex w-[11px] h-[275px] p-[2px] items-end gap-[10px] absolute right-0">
                        <div className="flex flex-col items-center pb-[133.5px] flex-1 self-stretch">
                          <div className="w-[6px] h-[133.5px] rounded-[8px] bg-gray-200">
                            
                          </div>
                        </div>
                    </div>
                  </div>

                  <div className="w-full py-3 px-4 flex justify-between items-center"
                    style={{
                      borderTop: '1px solid var(--Gray-200, #DDE5ED)',
                      background: 'var(--White, #F9FBFE)',
                    }}
                  >
                    <div className="text-primary-600 font-outfit text-sm font-medium leading-5">
                      View all projects
                    </div>
                  </div>

                

              </div>
            }
            arrow={false}
            trigger={["click"]}
          >
            <span onClick={() => setIsActive(!isActive)}>
              <ChevronIcon
                className="cursor-pointer text-black"
                style={{
                  transition: "transform 0.3s ease-in-out 0s",
                  transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                }}
              />
            </span>
          </Popover>

        </div>
      }
    >

      {children}
    </CustomLayout>
  );
};

export default TimeEstimatesLayout;







