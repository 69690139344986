import React, { useState } from "react";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";
import CustomLayout from "../../Core/LayoutV2/CustomLayout";
import { Popover } from "antd";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { useNavigate, useParams } from "react-router-dom";
import useSearch from "../../Core/hooks/useSearch";
import { EditPencilIcon } from "../../Core/svgV2/EditIcon";
import { DeleteIcon } from "../../Core/svgV2/DeleteIcon";
import { PlusIcon, PlusIconBold, PlusIconBoldBlack } from "../../Core/svgV2/PlusIcon";
import ClipBoardIcon from "../../Core/svgV3/ClipBoardIcon";
import { CopyIcon, CopyLinkIcon, CopyOutlinedIcon, CopyOutlineIcon } from "../../Core/svgV2/CopyIcon";
import ProjectDateModal from "./Modals/ProjectDateModal";
import { EllipseIcon } from "../../Core/svgV2/Ellipse";
import { ProjectDropDown, DisplayWeeks } from "./TimeEstimatesReusable";
import EstimatesMissingModal from "./Modals/EstimatesMissingModal";
import { color } from "framer-motion";
import CopyButton from "../../Core/CommonV2/CopyButton";
import { EditThreeIcon, EditTwoIcon } from "../../Core/svgV2/Edit02Icon";

const ProjectTimeEstimatesScrren = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();    
    
    const [isActive, setIsActive] = useState(false);
    const [projectDateModal, setProjectDateModal] = useState(false);
    const [discardModal, setDiscardModal] = useState(false);

    const weeks = [
        {
            "weekName": "Week 30",
            "startDate": "2024-07-25",
            "endDate": "2024-07-26"
        },
        {
            "weekName": "Week 31",
            "startDate": "2024-07-29",
            "endDate": "2024-08-02"
        },
        {
            "weekName": "Week 32",
            "startDate": "2024-08-05",
            "endDate": "2024-08-09"
        },
        {
            "weekName": "Week 33",
            "startDate": "2024-08-12",
            "endDate": "2024-08-16"
        },
        {
            "weekName": "Week 34",
            "startDate": "2024-08-19",
            "endDate": "2024-08-23"
        },
        {
            "weekName": "Week 35",
            "startDate": "2024-08-26",
            "endDate": "2024-08-30"
        }
    ]    

    const [weeksWithAssigneesAndNames, setWeeksWithAssigneesAndNames] = useState( [
            {
                "id": "30",
                "week": "Week 30",
                "startDate": "2024-07-25T16:15:41.704Z",
                "endDate": "2024-07-27T16:15:41.704Z",
                "totalHours": null,
                "assignees": [
                    {
                        "id": "4",
                        "assigneeId": "113",
                        "noOfDays": "3",
                        "noOfHours": "4",
                        "assignee_name": {
                            "firstname": "Damini",
                            "lastname": "Kesharkar"
                        }
                    },
                    {
                        "id": "5",
                        "assigneeId": "114",
                        "noOfDays": "3",
                        "noOfHours": "7",
                        "assignee_name": {
                            "firstname": "Siddhant",
                            "lastname": "Welinkar"
                        }
                    },
                    {
                        "id": "6",
                        "assigneeId": "115",
                        "noOfDays": "4",
                        "noOfHours": "4",
                        "assignee_name": {
                            "firstname": "Pradnya",
                            "lastname": "Chaure"
                        }
                    }
                ]
            },
            {
                "id": "31",
                "week": "Week 31",
                "startDate": "2024-07-29T16:15:41.704Z",
                "endDate": "2024-08-02T16:15:41.704Z",
                "totalHours": null,
                "assignees": [
                    {
                        "id": "6",
                        "assigneeId": "115",
                        "noOfDays": "4",
                        "noOfHours": "4",
                        "assignee_name": {
                            "firstname": "Pradnya",
                            "lastname": "Chaure"
                        }
                    },
                    {
                        "id": "4",
                        "assigneeId": "113",
                        "noOfDays": "3",
                        "noOfHours": "4",
                        "assignee_name": {
                            "firstname": "Damini",
                            "lastname": "Kesharkar"
                        }
                    },
                ]
            },
            {
                "id": "32",
                "week": "Week 32",
                "startDate": "2024-08-05T16:15:41.704Z",
                "endDate": "2024-08-09T16:15:41.704Z",
                "totalHours": null,
                "assignees": [
                    {
                        "id": "4",
                        "assigneeId": "116",
                        "noOfDays": "3",
                        "noOfHours": "4",
                        "assignee_name": {
                            "firstname": "Damini",
                            "lastname": "Kesharkar"
                        }
                    },
                ]
            },
            {
                "id": "33",
                "week": "Week 33",
                "startDate": "2024-08-12T16:15:41.704Z",
                "endDate": "2024-08-16T16:15:41.704Z",
                "totalHours": null,
                "assignees": [
                    {
                        "id": "4",
                        "assigneeId": "116",
                        "noOfDays": "3",
                        "noOfHours": "4",
                        "assignee_name": {
                            "firstname": "Ebrahim",
                            "lastname": "Kothari"
                        }
                    },
                ]
            },
    ]);

    const addWeek = () => {
        const lastWeek = weeksWithAssigneesAndNames[weeksWithAssigneesAndNames.length - 1];
        const lastWeekEndDate = new Date(lastWeek.endDate);

        const newStartDate = new Date(lastWeekEndDate);
        newStartDate.setDate(lastWeekEndDate.getDate() + 3);

        const newEndDate = new Date(newStartDate);
        newEndDate.setDate(newStartDate.getDate() + 5);

        const newWeekId = (parseInt(lastWeekId, 10) + 1).toString();

        const newWeek = {
            id: newWeekId,
            week: `week ${newWeekId}`,
            startDate: newStartDate.toISOString(),
            endDate: newEndDate.toISOString(),
            totalHours: null,
            assignees: []
        };

        setWeeksWithAssigneesAndNames([...weeksWithAssigneesAndNames, newWeek]);
    };

    const saveChanges = () => {
        setDiscardModal(true);
    };

    const addAndDuplicate = () => {
        const lastWeek = weeksWithAssigneesAndNames[weeksWithAssigneesAndNames.length - 1];
    
        const newWeekId = (parseInt(lastWeekId, 10) + 1).toString();
        const newStartDate = new Date(lastWeek.endDate);
        newStartDate.setDate(newStartDate.getDate() + 3); 
    
        const newEndDate = new Date(newStartDate);
        newEndDate.setDate(newStartDate.getDate() + 5);

        const newWeek = {
            id: newWeekId,
            week: `week ${newWeekId}`,
            startDate: newStartDate.toISOString(),
            endDate: newEndDate.toISOString(),
            totalHours: lastWeek.totalHours, 
            assignees: [...lastWeek.assignees], 
        };

        setWeeksWithAssigneesAndNames([...weeksWithAssigneesAndNames, newWeek]);
    };

    const projectList = [
        {
            "id": "506",
            "title": "Project Xd23",
            "projectImage": "",
            "startDate": "2024-04-08T02:19:36.132Z",
            "endDate": "2024-05-06T02:19:36.132Z"
        },
        {
            "id": "345",
            "title": "Project Xd",
            "projectImage": "https://link.ap1.storjshare.io/s/jxqpzikfjbsd7deh3qwfzxudujrq/mvp-api-test/contents/profile-pictures/Request_2_lt4czqjx.svg-1709037821995.svg?download=1",
            "startDate": "2024-03-05T16:46:23.984Z",
            "endDate": "2024-08-15T16:46:23.984Z"
        },
        {
            "id": "498",
            "title": "asdfghjk",
            "projectImage": "",
            "startDate": "2024-04-30T03:20:04.559Z",
            "endDate": "2024-05-26T03:20:04.559Z"
        },
        {
            "id": "487",
            "title": "Final Schedule",
            "projectImage": "",
            "startDate": "2024-04-02T00:14:50.250Z",
            "endDate": "2024-05-07T00:14:50.250Z"
        },
        {
            "id": "516",
            "title": "abc xyz",
            "projectImage": "",
            "startDate": "2024-05-21T06:26:31.144Z",
            "endDate": "2024-06-30T06:26:31.144Z"
        },
        {
            "id": "507",
            "title": "sprint tst",
            "projectImage": "",
            "startDate": "2024-04-01T02:24:07.077Z",
            "endDate": "2024-05-20T02:24:07.077Z"
    }];

    const handleProjectSelect = () => {
        setIsActive(false); 
      };
   
    const lastWeekId = weeksWithAssigneesAndNames[weeksWithAssigneesAndNames.length - 1]?.id;
    const [search, setSearch] = useState('');

    const filteredProjects = projectList.filter(project =>
        project.title.toLowerCase().includes(search.toLowerCase())
    );
    
    const getTitle = () => {
      let routeName = "";
  
      if (projectId) {
        const currentProject = projectList?.find((p) => p.id == projectId);
        if (currentProject?.title) {
          routeName = currentProject.title;
        }
  
        return routeName;
      } else {
        return "All Projects";
      }
    };

    return (
        <CustomLayout
            page="time-estimates"
            title={
                <div className="flex gap-x-1.5 ">
                    <span className="out-300-14 text-gray-500">Time Estimates</span>{" "}
                    <span className="out-300-14 text-gray-500">/</span>
                    <span className="out-500-14 text-black">{getTitle()}</span>{" "}

                    <Popover
                        open={isActive}
                        placement="bottom"
                        onOpenChange={(open) => {
                        setSearch("");
                        setIsActive(open);
                        }}
                        content={
                            <div className="w-[400px] shadow-md rounded-md bg-white border border-gray-100">
                                
                                <div className="p-2 px-4">
                                    <CustomSearchBox placeholder="Search Projects" search={search} setSearch={setSearch} />
                                </div>

                                <div className="max-h-[200px] overflow-y-auto">
                                    {/* {filteredData.map((project) => (
                                        <div
                                        key={project.id}
                                        onClick={() => {
                                            setIsActive(false);
                                            let screenName = "roadmap";
                                            if (defaultNavigation.isEnabled) {
                                            screenName = getScreenName(
                                                defaultNavigation.screenId
                                            );
                                            }
                                            const redirectTo =
                                            +lastVisitedSchedulePage?.projectId === +project.id
                                                ? lastVisitedSchedulePage?.redirectUrl
                                                : project.currentactivesprint
                                                ? `/schedule/${project.id}/${screenName}/${
                                                    screenName == "sprints"
                                                    ? project.currentactivesprint
                                                    : ""
                                                }`
                                                : `/schedule/${project.id}/${screenName}`;
                                            navigate(redirectTo);
                                        }}
                                        className={`w-full cursor-pointer hover:bg-gray-50 flex justify-between items-center py-2 px-4 ${
                                            project.id === projectId ? "bg-gray-50" : ""
                                        }`}
                                        >
                                        <div className="out-300-14 leading-5 text-gray-700">
                                            {project.title}
                                        </div>
                                        {project.id === projectId ? (
                                            <CheckIcon className="text-primary-600" />
                                        ) : (
                                            ""
                                        )}
                                        </div>
                                    ))} */}
                                </div>
                                
                                <div className="flex h-[255px] flex-col items-start self-stretch">
                                    <div className="w-full max-h-full overflow-y-auto">
                                        {/* {projectList.map(project => (
                                            <div key={project.id}>
                                                <ProjectDropDown project={project} />
                                            </div>
                                        ))} */}

                                        {filteredProjects.length ? (
                                            filteredProjects.map(project => (
                                                <ProjectDropDown 
                                                key={project.id} 
                                                project={project}
                                                onSelect={() => setIsActive(false)}
                                                />
                                            ))
                                            ) : (
                                            <div className="p-4 text-center text-gray-500">No projects found</div>
                                        )}

                                    </div>
                                    <div className="flex w-[11px] h-[275px] p-[2px] items-end gap-[10px] absolute right-0">
                                        <div className="flex flex-col items-center pb-[133.5px] flex-1 self-stretch">
                                            <div className="w-[6px] h-[133.5px] rounded-[8px] bg-gray-200">
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full py-3 px-4 flex justify-between items-center"
                                    style={{
                                        borderTop: '1px solid var(--Gray-200, #DDE5ED)',
                                        background: 'var(--White, #F9FBFE)',
                                    }}
                                >
                                    <div className="text-primary-600 font-outfit text-sm font-medium leading-5">
                                        View all projects
                                    </div>
                                </div>

                            </div>
                        }
                        arrow={false}
                        trigger={["click"]}
                        >
                            <span onClick={() => setIsActive(!isActive)}>
                            <ChevronIcon
                                className="cursor-pointer text-black"
                                style={{
                                transition: "transform 0.3s ease-in-out 0s",
                                transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
                                }}
                            />
                            </span>
                    </Popover>

                </div>
            }
        >  
            {/* Library Menu bar */}
            <div className="w-full h-[54px] flex-shrink-0">
                <div className="w-full h-[54px] flex-shrink-0 border-b border-[#DDE5ED] bg-[#F9FBFE]">
                    
                    <div className="inline-flex items-center gap-[8px] ml-[18px] mt-[9px] mb-[9px]">

                        <div className="flex items-start rounded-[8px] hover:bg-[#F5F5F5]"
                             role="button"
                             onClick={() => navigate(`/time-estimates/`)}
                        >
                            <div className="flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px]">
                                <ChevronIcon style={{
                                    width:'20px',
                                    height:'20px',
                                    transform: 'rotate(90deg)'
                                }}/>                                

                                <div className="text-[#292927] text-sm font-medium leading-[20px] font-outfit">
                                    {getTitle()}
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center gap-[8px]">

                            <div className="flex w-[75px] justify-end items-center">
                                <div className="flex px-2 py-0 justify-center items-center gap-1 flex-1 rounded-full bg-[#D1E9FF]">
                                    <div className="text-[#165ABF] text-[12px] font-medium leading-[18px] text-center font-outfit">
                                        Ongoing
                                    </div>
                                </div>
                            </div>

                            <div className="text-[#667085] text-[10px] font-normal leading-normal font-outfit">
                                {weeks.length} weeks
                            </div>

                            <div className="w-[1px] h-[8px] bg-[#C0CBD7]"></div>

                            <div className="text-[#667085] text-[10px] font-normal leading-normal font-outfit">
                                25 July 2024 - 30 Aug 2024
                            </div>

                            <div className="flex w-[14px] h-[14px] justify-center items-center"
                                role="button"
                                onClick={() => setProjectDateModal(true)}
                            >
                                <EditThreeIcon style={{
                                    width:'14px',
                                    height:'14px'
                                }}
                                >
                                </EditThreeIcon>
                            </div>

                        </div>

                    </div>
                                                
                    {/* Buttons  */}
                    <div className="inline-flex justify-end items-center gap-[8px] ml-[486px]">

                        <div className="flex p-[4px_8px] items-center gap-2 rounded-xl bg-[#D1E9FF] cursor-default">
                            <p className="text-[#101828] text-center text-xs font-medium leading-[18px] font-outfit">
                                Total estimated hours
                            </p>
                            <p className="text-gray900 text-center text-xs font-medium leading-[18px] font-outfit">
                                24h
                            </p>
                        </div>

                        <div className="flex items-start rounded-[8px]">
                            <div className="flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px] border border-[#165ABF] bg-[#165ABF] text-white cursor-default"
                                onClick={saveChanges}
                            >
                                <p className="text-white-custom font-outfit text-sm font-medium leading-sm">
                                    Save
                                </p>
                            </div>
                        </div>

                        <div className="flex items-start rounded-md">
                            <div className="flex p-1.5 justify-center items-center gap-2 rounded-md border border-[#EFF8FF] bg-[#EFF8FF] cursor-default"
                                onClick={addWeek}
                            >
                                <PlusIconBold style={{
                                    width:'20px',
                                    height:'20px'
                                }}>
                                </PlusIconBold>

                                <div className="text-[#144EA6] text-sm font-medium leading-5 font-outfit">
                                    Add Week
                                </div>
                            </div>
                        </div>

                        <div className="flex items-start rounded-lg">
                            <div className="flex p-1.5 justify-center items-center gap-2 rounded-md border border-[#DDE5ED] bg-[#F9FBFE] hover:bg-[#F5F5F5] cursor-pointer"
                                onClick={() => navigate(`/time-estimates/`)}
                            >
                                <div className="text-[#344054] text-sm font-medium leading-5 font-outfit">
                                    Cancel
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
            </div>

            
                <div className="w-full h-full overflow-y-scroll">
                    {weeksWithAssigneesAndNames.map((week) => {
                        const isLastWeek = week.id === lastWeekId;
                        return (
                        <div key={week.id}>
                            <DisplayWeeks
                                week={week}
                                isLastWeek={isLastWeek}
                            />
                        </div>
                        );
                    })}  
                    <div className="min-h-[calc(100vh-174px)] max-h-[calc(100vh-174px)] bg-gray-50">
                        {/* Add week */}
                        <div className="inline-flex items-start rounded-[8px]" style={{ marginLeft: '13px', marginTop: '9px' }}>
                            <div className="flex py-[5px] px-[10px] justify-center items-center gap-[8px] rounded-[4px] border border-[#DDE5ED] bg-[#F9FBFE] cursor-pointer hover:bg-gray-50 transition-colors duration-300"
                            onClick={addWeek} 
                            >
                                <PlusIconBoldBlack style={{
                                        width:'20px',
                                        height:'20px',
                                    }}                             
                                >
                                </PlusIconBoldBlack>

                                <div 
                                    className="text-[#344054] text-[14px] font-medium leading-[20px]" 
                                    style={{ fontFamily: 'Outfit, sans-serif' }}
                                >
                                    Add Week
                                </div>
                            </div>  
                        </div>

                        {/* Add and Duplicate  */}
                        <div className="inline-flex items-start rounded-[8px]">
                            <div className="flex p-[5px_10px] justify-center items-center gap-[8px] rounded-[4px] cursor-pointer"
                                onClick={addAndDuplicate}                
                            >
                                <CopyOutlineIcon style={{
                                    width:'20px',
                                    height:'20px',
                                }}>

                                </CopyOutlineIcon>

                                <div className="text-[#667085] text-sm font-medium leading-[20px]" style={{ fontFamily: 'Outfit, sans-serif' }}>
                                    Add & Duplicate
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            {projectDateModal && (
                <ProjectDateModal
                    onCancel={() => {
                        setProjectDateModal(false);
                    }}
                    visible={projectDateModal}
                />
            )}

            <EstimatesMissingModal
                onClose={() => setDiscardModal(false)}
                onDiscard={() => {
                    setDiscardModal(false);
                }}
                
                visible={discardModal} 
                title="Time estimates are missing!"
                description="You missed adding the time estimates for Week 3 to Week 34. Please add the estimates before the sprint begins."
                buttonOneText="I’ll Do It Later" 
                buttonTwoText="Add Time Estimates"                    
            />

            </CustomLayout>
            
            );
};

export default ProjectTimeEstimatesScrren;
