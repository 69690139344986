import React from "react";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { ChevronDoubleIcon } from "../../../Core/svgV2/Chevron";
import GoogleLogin from "react-google-login";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import { ProjectStartDateEndDate } from "../TimeEstimatesReusable";


const ProjectDateModal = ({ onCancel, visible }) => {
    return (
    <CustomModal
      visible={visible}
      width="352px"
      onCancel={() => onCancel()}
      body={
        <div className="inline-flex flex-col items-start gap-4 p-3.5 rounded-lg bg-[#F9FBFE] shadow-lg">

            <div className="flex justify-end items-center">
                <div className="text-[#292927] text-sm font-medium leading-5 font-outfit">
                    Edit Project Dates
                </div>
            </div>

            <div className="flex flex-col items-start w-[320px]">
                <div className="flex flex-col items-start gap-1.5 self-stretch">
                    <div className="flex flex-col items-start gap-1.5 self-stretch">
                        <div className="text-[#344054] text-sm font-medium leading-5 font-outfit">
                            Start date
                        </div>
                            <ProjectStartDateEndDate 
                                startDate={"25 July 2024"}
                                endDate={"30 Aug 2025"}
                                type={"Start"}                              
                            
                            />
                        {/* <input
                            className="flex p-1.5 items-center gap-2 self-stretch rounded border border-[#DDE5ED] bg-[#F9FBFE]"
                            type="text"
                            value="3 Jan 2024"
                        /> */}
                            {/* <div className="flex items-center gap-2 flex-1">
                                <div
                                    className="flex-1 text-[#101828] text-sm font-light leading-5"
                                >
                                    3 Jan 2024
                                </div>
                            </div> */}                       
                    </div>
                </div>
            </div>

            <div className="flex w-[320px] flex-col items-start">
                <div className="flex flex-col items-start gap-1.5 self-stretch">
                    <div className="flex flex-col items-start gap-1.5 self-stretch">
                        <div
                            className="text-[#344054] text-sm font-medium leading-5"
                            style={{ fontFamily: 'Outfit, sans-serif' }}
                        >
                            End date
                        </div>
                        <ProjectStartDateEndDate 
                            startDate={"25 July 2024"}
                            endDate={"30 Aug 2025"}
                            type={"End"}                              
                        />
                        {/* <input
                            className="flex p-1.5 items-center gap-2 self-stretch rounded border border-[#DDE5ED] bg-[#F9FBFE]"
                            type="text"
                            value="23 August 2024"
                        /> */}
                    </div>
                </div>
            </div>

            <div className="flex justify-end items-start gap-3 self-stretch">
                
                <div className="flex items-start rounded-lg">
                    <div className="flex p-1.5 justify-center items-center gap-2 rounded cursor-pointer"
                        onClick={() => onCancel()}
                    >
                        <div
                            className="text-[#667085] text-sm font-medium leading-5"
                            style={{ fontFamily: 'Outfit, sans-serif' }}
                        >
                            Cancel
                        </div>
                    </div>
                </div>

                <div className="flex items-start rounded-lg">
                    <div className="w-[60px] flex p-1.5 justify-center items-center gap-2 rounded border border-[#165ABF] bg-[#165ABF] shadow-xs cursor-pointer">
                        <div
                            className="text-[#F9FBFE] text-sm font-medium leading-5"
                            style={{ fontFamily: 'Outfit, sans-serif' }}
                        >
                            Save
                        </div>
                    </div>
                </div>
            </div>

        </div>
      }
    />)
};

export default ProjectDateModal;
