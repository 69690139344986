import React, { useState } from "react";
import { CrossIcon } from "../../../Core/svgV2/CrossIcon";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import CustomModal from "../../../Core/CommonV2/CustomModal";
import { useAppDispatch, useAppSelector } from "../../../Core/redux/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";
import { CheckIcon } from "../../../Core/svgV2/Check";
import { PlusIconSecondary } from "../../../Core/svgV2/PlusIcon";

const dummyTeams = ["development", "design", "quality assurance"];

const AssigneesReportsModal = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const { deleteLabelModal } = useAppSelector((state) => state.schedule);

  const [teamName, setTeamName] = useState("");
  const [formState, setFormState] = useState({ errors: {} });

  // State for tracking which charts are added
  const [chartsState, setChartsState] = useState({
    chart1: false,
    chart2: false,
    chart3: false,
    chart4: false,
  });

  const handleCreate = () => {
    const normalizedTeamName = teamName.trim().toLowerCase();

    if (dummyTeams.includes(normalizedTeamName)) {
      setFormState({
        errors: {
          teamName: { message: "Team already exists" },
        },
      });
    } else {
      setFormState({ errors: {} });
      // Proceed with the creation logic
      onClose();
    }
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setTeamName(inputValue);

    const normalizedTeamName = inputValue.trim().toLowerCase();

    if (dummyTeams.includes(normalizedTeamName)) {
      setFormState({
        errors: {
          teamName: { message: "Team already exists" },
        },
      });
    } else {
      setFormState({ errors: {} });
    }
  };

  const handleChartToggle = (chart) => {
    setChartsState((prevState) => ({
      ...prevState,
      [chart]: !prevState[chart],
    }));
  };

  return (
    <CustomModal
      visible={visible}
      width="600px"
      onCancel={() => onClose()}
      body={
        <div className="p-4">
          <div className="flex justify-between">
            <p className="text-black out-500-14">Assignees</p>
            <button onClick={() => onClose()}>
              <CrossIcon className="text-gray-700" />
            </button>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex gap-4 mt-4">
              <div
                className={`flex flex-col gap-3 flex-1 rounded-lg p-4 ${
                  chartsState.chart1 ? "bg-primary-50" : "bg-gray-100"
                }`}
              >
                <div>
                  <img src="\images\v3\reports\ProjectsGraph1.svg" />
                </div>
                <div>
                  <div className="out-500-14 text-black">
                    Total time estimated v/s time spent
                  </div>
                  <div className="out-300-12 text-grey-500 h-[54px]">
                    Compare the estimated time with the actual time spent on
                    tasks to assess project performance during the selected
                    weeks(s).
                  </div>
                </div>
                <CustomButton
                  width={chartsState.chart1 ? "90px" : "110px"}
                  text={chartsState.chart1 ? "Added" : "Add Chart"}
                  onClick={() => handleChartToggle("chart1")}
                  className={`${
                    chartsState.chart1
                      ? "whitespace-nowrap bg-primary-200 text-white"
                      : "whitespace-nowrap bg-primary-600 text-white"
                  }`}
                  icon={
                    chartsState.chart1 ? (
                      <CheckIcon className="text-white" />
                    ) : (
                      <PlusIconSecondary className="text-white" />
                    )
                  }
                  iconAlignment="right"
                />
              </div>
              <div
                className={`flex flex-col gap-3 flex-1 rounded-lg p-4 ${
                  chartsState.chart2 ? "bg-primary-50" : "bg-gray-100"
                }`}
              >
                <div>
                  <img src="\images\v3\reports\ProjectsGraph2.svg" />
                </div>
                <div>
                  <div className="out-500-14 text-black">
                    Team time on projects
                  </div>
                  <div className="out-300-12 text-grey-500 h-[54px]">
                    View the time spent by each team on different projects
                    during the selected weeks(s).
                  </div>
                </div>
                <CustomButton
                  width={chartsState.chart2 ? "90px" : "110px"}
                  text={chartsState.chart2 ? "Added" : "Add Chart"}
                  onClick={() => handleChartToggle("chart2")}
                  className={`${
                    chartsState.chart2
                      ? "whitespace-nowrap bg-primary-200 text-white"
                      : "whitespace-nowrap bg-primary-600 text-white"
                  }`}
                  icon={
                    chartsState.chart2 ? (
                      <CheckIcon className="text-white" />
                    ) : (
                      <PlusIconSecondary className="text-white" />
                    )
                  }
                  iconAlignment="right"
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div
                className={`flex flex-col gap-3 flex-1 rounded-lg p-4 ${
                  chartsState.chart3 ? "bg-primary-50" : "bg-gray-100"
                }`}
              >
                <div>
                  <img src="\images\v3\reports\ProjectsGraph3.svg" />
                </div>
                <div>
                  <div className="out-500-14 text-black">
                    Task v/s bug ratio
                  </div>
                  <div className="out-300-12 text-grey-500 h-[54px]">
                    View the ratio of the number of tasks to bugs within
                    projects during the selected weeks(s).
                  </div>
                </div>
                <CustomButton
                  width={chartsState.chart3 ? "90px" : "110px"}
                  text={chartsState.chart3 ? "Added" : "Add Chart"}
                  onClick={() => handleChartToggle("chart3")}
                  className={`${
                    chartsState.chart3
                      ? "whitespace-nowrap bg-primary-200 text-white"
                      : "whitespace-nowrap bg-primary-600 text-white"
                  }`}
                  icon={
                    chartsState.chart3 ? (
                      <CheckIcon className="text-white" />
                    ) : (
                      <PlusIconSecondary className="text-white" />
                    )
                  }
                  iconAlignment="right"
                />
              </div>
              <div
                className={`flex flex-col gap-3 flex-1 rounded-lg p-4 ${
                  chartsState.chart4 ? "bg-primary-50" : "bg-gray-100"
                }`}
              >
                <div>
                  <img src="\images\v3\reports\ProjectsGraph4.svg" />
                </div>
                <div>
                  <div className="out-500-14 text-black">
                    Task v/s bug time ratio
                  </div>
                  <div className="out-300-12 text-grey-500 h-[54px]">
                    View the ratio of time spent on tasks to time spent on bugs
                    within projects during the selected weeks(s).
                  </div>
                </div>
                <CustomButton
                  width={chartsState.chart4 ? "90px" : "110px"}
                  text={chartsState.chart4 ? "Added" : "Add Chart"}
                  onClick={() => handleChartToggle("chart4")}
                  className={`${
                    chartsState.chart4
                      ? "whitespace-nowrap bg-primary-200 text-white"
                      : "whitespace-nowrap bg-primary-600 text-white"
                  }`}
                  icon={
                    chartsState.chart4 ? (
                      <CheckIcon className="text-white" />
                    ) : (
                      <PlusIconSecondary className="text-white" />
                    )
                  }
                  iconAlignment="right"
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AssigneesReportsModal;
