import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// import ConversationsScreen from "./Modules/Conversations/ConversationsScreen";

import io from "socket.io-client";
import { useAppDispatch, useAppSelector } from "./Core/redux/hooks";

// import ProjectsScreen from "./Modules/Projects/ProjectsScreen";

import "./FireBase/firebaseInit";
import ActivityScreen from "./Modules/ActivityLog/ActivityLogScreen";
// import ProductSettingsScreen from "./Modules/Settings/Product Settings/ProductSettingsScreen";
import InviteMembers from "./Modules/Settings/InviteMembers";
// import FilePreviewScreenNew from "./Modules/Conversations/FilePreviewScreenNew";
// import FilePreviewScreen from "./Modules/Conversations/FilePreviewScreen";
import AppWrapper from "./AppWrapper";
import SwitchWorkspaceScreen from "./Modules/SwitchWorkspace/SwitchWorkspaceScreen";
import { setActiveUsers } from "./Core/redux/slices/tenant.slice";
import PrivacyPolicy from "./Modules/PolicyAndService/PrivacyPolicy";
import TermsAndServices from "./Modules/PolicyAndService/TermsAndServices";

import ConversationsScreenV2 from "./ModulesV2/Conversations/ConversationsScreen";
import LibraryScreen from "./ModulesV2/Library/LibraryScreen";
import DashboardScreen from "./ModulesV2/Dashboard/DashboardScreen";
import SprintsScreen from "./ModulesV2/Schedule/Sprints/SprintsScreen";
import TaskScreen from "./ModulesV2/Schedule/Task/TaskScreen";
import ReportsScreen from "./ModulesV2/Schedule/Reports/ReportsScreen";
import ReleaseNotesScreen from "./ModulesV2/Schedule/Reports/ReleaseNotes/ReleaseNotesScreen";
import PlannerScreen from "./ModulesV2/Schedule/Planner/PlannerScreen";
import RoadmapScreen from "./ModulesV2/Schedule/Roadmap/RoadmapScreen";
import AllSprintsScreen from "./ModulesV2/Schedule/Sprints/AllSprintsScreen";
import CompleteFormV2 from "./ModulesV2/Onboarding/Product Onboarding/CompleteForm";
import NewLoginScreenV2 from "./ModulesV2/Login/NewLoginScreen";
import SignUpScreenV2 from "./ModulesV2/Login/SignUpScreen";

import ResetPasswordV2 from "./ModulesV2/Login/ResetPassword";
import ProjectSetupV2 from "./ModulesV2/Onboarding/Project Onboarding/ProjectSetup";
import SettingsScreen from "./ModulesV2/Settings/SettingsScreen";
import ManageProfile from "./ModulesV2/Settings/ManageProfile";
import ForgotPasswordScreenV2 from "./ModulesV2/Login/ForgotPasswordScreen";
import AcceptInvite from "./ModulesV2/Login/AcceptInvite";
import MyBoardScreen from "./ModulesV2/Schedule/MyBoard/MyBoardScreen";
import MyBoardTaskScreen from "./ModulesV2/Schedule/MyBoard/MyBoardTaskScreen";
import TimelineScreen from "./ModulesV3/Schedule/Timeline/TimelineScreen";
import SubtaskScreen from "./ModulesV2/Schedule/MyBoard/SubTaskScreen";
import ScheduleSubtaskScreen from "./ModulesV2/Schedule/Task/ScheduleSubtaskScreen";
import PersonalDCFTracker from "./ModulesV3/Footprint/PersonalDCFTracker";
import MyCalendarScreen from "./ModulesV3/Calendar/MyCalendarScreen";
import TimeEstimatesScreen from "./ModulesV2/TimeEstimates/TimeEstimatesScreen";
import ProjectTimeEstimatesScrren from "./ModulesV2/TimeEstimates/ProjectTimeEstimatesScrren";
import ReportsScreenV3 from "./ModulesV3/Reports/ReportsScreenV3";

const App: React.FC = () => {
  const queryClient = new QueryClient();
  const { user, loggedIn } = useAppSelector((state) => state.userDetails);
  // const { tenantDetails } = useAppSelector((state) => state.tenant);
  const dispatch = useAppDispatch();

  const socketConnectionObject: {
    transports: string[];
    path?: string;
    auth: {
      token: string;
      userId: string | number;
    };
    autoConnect: boolean;
  } = {
    transports: ["websocket"],
    auth: {
      token: user.token,
      userId: user.id,
    },
    autoConnect: false,
  };

  if (process.env.REACT_APP_ENV !== "local") {
    socketConnectionObject.path = "/conversation-socket";
  }
  // conection to socket
  const socket = io(
    `${process.env.REACT_APP_SOCKET_URL}`,
    socketConnectionObject
  );

  useEffect(() => {
    // socket.auth = {
    //   username: user.id,
    // };

    if (socket && loggedIn) {
      socket.connect();
      socket.emit("join", user.id);
      console.log("mounting");

      socket.on("userPresence", ({ currentActiveUsers }) =>
        dispatch(setActiveUsers(currentActiveUsers))
      );

      // socket.on("connect", () => {
      //   console.log("Connected to the server");
      // });

      // socket.on("disconnect", () => {
      //   socket.connect();
      // });
    } else {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
      socket.off("userPresence");
    }

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("pong");
      socket.off("userPresence");
      console.log("unmounting");
    };
  }, [loggedIn, user.token, socket]);
  return (
    <QueryClientProvider client={queryClient}>
      <AppWrapper>
        <div className="relative App">
          <Routes>
            <Route path="/" element={<NewLoginScreenV2 />} />

            <Route path="/login" element={<NewLoginScreenV2 />} />
            <Route path="/signup" element={<SignUpScreenV2 />} />
            <Route path="/accept-invite" element={<AcceptInvite />} />

            <Route
              path="/forgot-password"
              element={<ForgotPasswordScreenV2 />}
            />
            <Route path="/reset-password" element={<ResetPasswordV2 />} />

            <Route path="/onboarding" element={<CompleteFormV2 />} />
            <Route path="/project-onboarding" element={<ProjectSetupV2 />} />
            <Route
              path="/dashboard"
              element={<DashboardScreen socket={socket} />}
            />

            {/* <Route path="/overview/:projectId" element={<OverviewScreen />} /> */}
            {/* <Route path="/schedule2/:projectId" element={<ScheduleScreen />} />
          <Route path="/schedule/:projectId" element={<ScheduleScreenNew />} /> */}
            {/* <Route path="projects" element={<ProjectsScreen />} /> */}
            {/* <Route path="/settings/:projectId" element={<SettingScreen />} /> */}
            <Route path="/settings" element={<SettingsScreen />} />
            <Route path="/invite-members" element={<InviteMembers />} />
            {/* <Route path="/notifications/" element={<MainScreen />} /> */}
            {/* <Route
            path="/vault/:projectId/*"
            element={<VaultScreen socket={socket} />}
          /> */}
            {/* <Route
            path="/conversations"
            element={<ConversationsScreen socket={socket} />}
          /> */}
            <Route path="/activity-log" element={<ActivityScreen />} />

            {/* <Route path="/product-settings" element={<ProductSettingsScreen />} /> */}

            {/* <Route path="/preview2" element={<FilePreviewScreen />} /> */}
            <Route
              path="/switch-workspace"
              element={<SwitchWorkspaceScreen />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-and-services" element={<TermsAndServices />} />

            <Route
              path="/dashboardV2"
              element={<DashboardScreen socket={socket} />}
            />
            <Route
              path="/dashboardV2/:projectId"
              element={<DashboardScreen socket={socket} />}
            />
            <Route
              path="/dashboard/:projectId"
              element={<DashboardScreen socket={socket} />}
            />

            {/* <Route path="/library/:projectId?/*" element={<LibraryScreen />} /> */}
            {/* optional path parameters are not supported by react router 6.4  */}
            <Route
              path="/library"
              element={<LibraryScreen socket={socket} />}
            />
            {
              <Route
                path="/library/my-library/*"
                element={<LibraryScreen socket={socket} />}
              />
            }
            <Route
              path="/library/global/*"
              element={<LibraryScreen socket={socket} />}
            />
            <Route
              path="/library/:projectId/*"
              element={<LibraryScreen socket={socket} />}
            />

            {/* Schedule  Routes*/}
            <Route
              path="/schedule/:projectId/sprints"
              element={<AllSprintsScreen />}
            />
            <Route
              path="/schedule/:projectId/sprints/null"
              element={<AllSprintsScreen />}
            />
            <Route
              path="/schedule/:projectId/sprints/:sprintId/"
              element={<SprintsScreen />}
            />
            <Route
              path="/schedule/:projectId/task/:taskId"
              element={<TaskScreen />}
            />
            <Route
              path="/schedule/:projectId/task/:taskId/subtask/:subtaskId"
              element={<ScheduleSubtaskScreen />}
            />

            <Route
              path="/schedule/:projectId/reports"
              element={<ReportsScreen />}
            />
            <Route
              path="/schedule/:projectId/backlog"
              element={<PlannerScreen />}
            />
            <Route
              path="/schedule/:projectId/reports/release-notes"
              element={<ReleaseNotesScreen />}
            />
            <Route
              path="/schedule/:projectId/reports/release-notes/:parentNoteId"
              element={<ReleaseNotesScreen />}
            />
            <Route
              path="/schedule/:projectId/roadmap"
              element={<TimelineScreen />}
            />
            <Route
              path="/schedule/:projectId/timeline"
              element={<TimelineScreen />}
            />
            <Route path="/onboardingV2" element={<CompleteFormV2 />} />

            <Route path="/loginV2" element={<NewLoginScreenV2 />} />
            <Route path="/signupV2" element={<SignUpScreenV2 />} />
            <Route
              path="/forgot-passwordV2"
              element={<ForgotPasswordScreenV2 />}
            />
            <Route path="/reset-passwordV2" element={<ResetPasswordV2 />} />
            <Route path="/project-onboardingV2" element={<ProjectSetupV2 />} />

            <Route path="/settingsV2" element={<SettingsScreen />} />
            <Route path="/manage-profile" element={<ManageProfile />} />

            <Route path="/time-estimates" element={<TimeEstimatesScreen />} />
            <Route path="/time-estimates/:projectId" element={<ProjectTimeEstimatesScrren />}/>

            {/* Routes for V3 */}

            <Route path="/my-board" element={<MyBoardScreen />} />

            <Route path="/my-calendar" element={<MyCalendarScreen />} />
            <Route path="/reports" element={<ReportsScreenV3 />} />

            <Route path="/my-footprint" element={<PersonalDCFTracker />} />

            <Route
              path="/my-board/task/:taskId"
              element={<MyBoardTaskScreen />}
            />
            <Route
              path="/my-board/task/:taskId/subtask/:subtaskId"
              element={<SubtaskScreen />}
            />

            {/* Restoring the old messages ui */}
            {/* <Route
              path="/messages"
              element={<ConversationLayout socket={socket} />}
            />
            <Route
              path="/messagesV3"
              element={<ConversationLayout socket={socket} />}
            /> */}
            <Route
              path="/messages"
              element={<ConversationsScreenV2 socket={socket} />}
            />
          </Routes>
        </div>
      </AppWrapper>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
