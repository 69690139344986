import React, { useState } from "react";
import CustomSearchBox from "../../Core/CommonV2/CustomSearchBox";

const TimeEstimatesMenu = ({ search, setSearch }) => {
  return (
    <div className="px-6 py-3 bg-white border-b border-gray-200">
      <h1 className="text-black out-500-14">Time Estimates</h1>
      <p className="mt-0.5 out-300-14 text-gray-500">
        Add the weekly time estimates for each team member within projects
      </p>
      <div className="flex items-center justify-between mt-3.5">
        <div className="flex items-center gap-x-4">
        <CustomSearchBox placeholder="Search Projects"             
            search={search}
            setSearch={(e) => {
              setSearch(e.toLowerCase());
            }} />
        </div>
      </div>
    </div>
  );
};

export default TimeEstimatesMenu;
