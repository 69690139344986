import React, { useState } from "react";
import PageTitle from "../PageTitle";
import CustomSearchBox from "../../../Core/CommonV2/CustomSearchBox";
import CustomButton from "../../../Core/CommonV2/CustomButton";
import NewTeamModal from "../../Schedule/Modals/NewTeamModal";
import { EditIcon } from "../../../Core/svgV2/EditIcon";
import { DeleteIcon } from "../../../Core/svgV2/DeleteIcon";
import { Tooltip } from "antd";
import CustomInputBox from "../../../Core/CommonV2/CustomInputBox";

export const TeamItem = ({ team }) => {
  const [edit, setEdit] = useState(false);
  // const dispatch = useAppDispatch();
  // const [color, setColor] = useState(label.color);
  const [teamName, setTeamName] = useState(team.name);
  // const [dateRange, setDateRange] = useState<[Dayjs, Dayjs] | null>(null);

  // useEffect(() => {
  //   setLabelName(label.name);
  //   setColor(label.color);
  //   setDateRange(
  //     label.startDate && label.endDate
  //       ? [dayjs(label.startDate), dayjs(label.endDate)]
  //       : null
  //   );
  // }, [label]);

  const handelUpdateLabel = async () => {
    try {
      // await client.put("/schedule/label", {
      //   labelId: label.id,
      //   labelName,
      //   startDate: dateRange ? dayjs(dateRange[0]).toISOString() : null,
      //   endDate: dateRange ? dayjs(dateRange[1]).toISOString() : null,
      //   color: color?.textColor ? color.textColor : "#7DC7FF",
      //   isMyBoard: projectId ? false : true,
      //   labelColorId: color.id,
      // });
      // dispatch(getLabels({ projectId, isMyBoard: projectId ? false : true }));
      console.log("saved");
    } catch (e) {
      console.log("Some error happened while updating the label", e);
    } finally {
      setEdit(false);
    }
  };

  if (edit) {
    return (
      <div className="py-2 gap-x-2 flex justify-between">
        <CustomInputBox
          className="w-full"
          autoFocus
          placeholder="Enter a name"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
        />
        {/* <CustomColorPicker
            currentColor={color}
            onColorChange={(color) => setColor(color)}
          /> */}
        <CustomButton
          text="Save"
          className="bg-transparent text-primary-700 out-500-14"
          height="30px"
          onClick={handelUpdateLabel}
        />
        <CustomButton
          text="Cancel"
          className="bg-transparent text-gray-500 out-500-14"
          height="30px"
          onClick={() => setEdit(false)}
        />
      </div>
    );
  }

  return (
    <div className="py-2.5 group flex justify-between">
      {teamName}
      <div className="group-hover:flex hidden gap-x-4 items-center">
        <Tooltip title="Edit">
          <button onClick={() => setEdit(true)}>
            <EditIcon />
          </button>
        </Tooltip>
        <Tooltip title="Delete">
          <button onClick={() => console.log("delete")}>
            <DeleteIcon />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

const WorkspaceTeams = () => {
  const [newTeamVisible, setNewTeamVisible] = useState(false);
  const [search, setSearch] = useState("");

  const EmptyState = () => {
    return (
      <div className=" w-full flex  items-center justify-center flex-col rounded-lg gap-y-6 h-[calc(100vh-300px)] px-4 py-6">
        <div className="out-500-14 text-center text-black">No team created</div>
        <img
          src="/images/v2/settings/frosty-search.svg"
          className="w-[146px]"
        />
        <p className="out-300-14 text-gray-500">
          Create a new team and assign to members
        </p>

        <CustomButton
          text="Create New Team"
          height="30px"
          onClick={() => setNewTeamVisible(true)}
        />
      </div>
    );
  };

  const dummyTeams = [
    { id: "1", name: "development" },
    { id: "2", name: "design" },
    { id: "3", name: "quality assurance" },
  ];

  const handleSearch = () => {
    let filteredTeams = [...dummyTeams];
    if (search) {
      const regex = new RegExp(search, "i");

      filteredTeams = filteredTeams.filter((teams) => {
        return regex.test(teams.id) || regex.test(teams.name);
      });
    }
    return filteredTeams;
  };

  return (
    <div>
      <PageTitle title={"Teams"} subtext={"Manage workspace teams."} />
      <div className="my-6 flex justify-between">
        <CustomSearchBox
          placeholder="Search by name"
          style={{ width: "50%" }}
          search={search}
          setSearch={(e) => setSearch(e)}
        />

        {/* {+user.roleId < 3 && ( */}
        <CustomButton
          height="30px"
          text="Create Team"
          onClick={() => setNewTeamVisible(true)}
        />
        {/* )} */}
      </div>
      {dummyTeams.length === 0 ? (
        <EmptyState />
      ) : (
        <div>
          {handleSearch().map((team) => (
            <div key={team.id} className="border-b border-gray-200">
              <TeamItem team={team} />
            </div>
          ))}
        </div>
      )}

      <NewTeamModal
        visible={newTeamVisible}
        onClose={() => setNewTeamVisible(false)}
      />
    </div>
  );
};

export default WorkspaceTeams;
