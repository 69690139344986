import React, { useState } from "react";
import CustomBulletChart from "./CustomBulletChart";
import CustomGroupedBarChart from "./CustomGroupedBarChart";
import DividedBarChart from "./DividedBarChart";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { Tooltip } from "antd";

const ProjectCard = ({ project }) => {
  const progress = Math.round(project?.progress || 0);

  return (
    <div className="flex flex-col gap-4 rounded border pt-4 w-full h-full rounded-xl bg-white">
      <div className="flex justify-between px-4">
        <div>
          <div className="out-500-10 text-gray-400">
            {project?.title || "Project Name"}
          </div>
          <Tooltip
            title={<div className="">{project?.range}</div>}
            placement={"bottom"}
          >
            <div className="">
              <span className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px] mr-1">
                {progress}%
              </span>
              Complete
            </div>
          </Tooltip>
        </div>
        <div>
          {project?.projectImage ? (
            <img
              src={project.projectImage}
              alt="Project"
              className="w-6 h-6 rounded-full"
            />
          ) : (
            <div className="w-6 h-6 bg-gray-200 rounded-full flex items-center justify-center">
              S
            </div>
          )}
        </div>
      </div>

      {/* Progress Bar */}
      <div>
        <div className="relative w-full h-0.5 bg-gray-100 overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-primary-600"
            style={{ width: `${progress}%` }}
          ></div>
        </div>

        <div className="flex justify-between py-3 px-4">
          <div className="out-300-12 text-gray-500">Active Phases</div>
          <div className="flex gap-2">
            {project?.phases?.map((phase, index) => (
              <Tooltip
                title={<div className="">{phase?.name}</div>}
                placement={"bottom"}
                key={index}
              >
                <div
                  key={index}
                  className="w-4 h-4 rounded-full"
                  style={{ backgroundColor: phase.colorCode || "gray" }}
                ></div>
              </Tooltip>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomCarousel = ({ projects }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  // Number of projects to show at a time
  const itemsPerPage = 4;

  // Determine the number of slides/pages
  const totalSlides = Math.ceil(projects.length / itemsPerPage);

  // Handle next button click
  const nextSlide = () => {
    if (currentIndex < totalSlides - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  // Handle previous button click
  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  // Handle dot click
  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full">
      {/* Project Cards */}
      <div className="flex overflow-hidden">
        <div
          className="flex transition-transform duration-500"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
            width: `${totalSlides * 100}%`,
          }}
        >
          {projects.map((project, index) => (
            <div
              key={project.id}
              className="w-1/4 px-2"
              style={{
                minWidth: `${100 / itemsPerPage}%`,
              }}
            >
              <ProjectCard project={project} />
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-center mt-4 gap-2">
        {/* Previous Arrow */}
        <button
          onClick={prevSlide}
          className={` ${
            currentIndex === 0
              ? "cursor-not-allowed text-gray-400"
              : "text-gray-700"
          }`}
        >
          <ChevronIcon className="rotate-90" />
        </button>

        {/* Dots */}
        {Array.from({ length: totalSlides }).map((_, index) => (
          <button
            key={index}
            onClick={() => goToSlide(index)}
            className={`my-auto rounded-full ${
              currentIndex === index
                ? "w-2 h-2 bg-primary-600"
                : "w-[6px] h-[6px] bg-gray-400"
            }`}
          ></button>
        ))}

        {/* Next Arrow */}
        <button
          onClick={nextSlide}
          className={`${
            currentIndex === totalSlides - 1
              ? "cursor-not-allowed text-gray-400"
              : "text-gray-700"
          }`}
        >
          <ChevronIcon className="-rotate-90" />
        </button>
      </div>
    </div>
  );
};

const CustomBarData = [
  { id: "Task A", estimatedTime: 30, actualTime: 20 },
  { id: "Task B", estimatedTime: 40, actualTime: 45 },
  { id: "Task C", estimatedTime: 25, actualTime: 20 },
  { id: "Task D", estimatedTime: 35, actualTime: 30 },
  { id: "Task E", estimatedTime: 45, actualTime: 40 },
];

const CustomBarKeys = ["estimatedTime", "actualTime"];

const AllChartsScreen = ({ selectedCharts }) => {
  const [projectList, setProjectList] = useState([
    {
      id: "1",
      title: "Project 1",
      progress: 25,
      phases: [{ colorCode: "red", name: "Development" }],
      range: "1 Jan - 15 Feb, 2024",
    },
    {
      id: "2",
      title: "Project 2",
      progress: 50,
      phases: [{ colorCode: "blue", name: "Design" }],
      range: "16 Feb - 28 Mar, 2024",
    },
    {
      id: "3",
      title: "Project 3",
      progress: 75,
      phases: [{ colorCode: "green", name: "Ideation" }],
      range: "1 Apr - 15 May, 2024",
    },
    {
      id: "4",
      title: "Project 4",
      progress: 80,
      phases: [{ colorCode: "orange", name: "Deployment" }],
      range: "16 May - 30 June, 2024",
    },
    {
      id: "5",
      title: "Project 5",
      progress: 90,
      phases: [{ colorCode: "purple", name: "Maintenance" }],
      range: "1 July - 15 Aug, 2024",
    },
    {
      id: "6",
      title: "Project 6",
      progress: 95,
      phases: [{ colorCode: "yellow", name: "Testing" }],
      range: "16 Aug - 30 Sep, 2024",
    },
    {
      id: "7",
      title: "Project 7",
      progress: 100,
      phases: [{ colorCode: "pink", name: "Completed" }],
      range: "1 Oct - 15 Nov, 2024",
    },
  ]);

  // Function to conditionally render the charts
  const renderCharts = () => {
    const chartComponents = [];

    selectedCharts.forEach((chart, index) => {
      let chartComponent;
      let keysHeight;

      switch (chart.type) {
        case 1: // CustomBulletChart for 'Total Time Estimated vs Time Spent'
          chartComponent = (
            <CustomBulletChart
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              heightKey={21}
            />
          );
          break;
        case 7: // CustomBulletChart for 'Total Time Estimated vs Time Spent (Tasks)'
          chartComponent = (
            <CustomBulletChart
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              heightKey={21}
            />
          );
          break;

        case 2: // DividedBarChart for 'Team Time on Projects'
          keysHeight =
            chart.keys.length < 6
              ? 21
              : chart.keys.length >= 6 && chart.keys.length < 12
              ? 42
              : 86;
          chartComponent = (
            <DividedBarChart
              key={index}
              heading={chart.title}
              tooltip={chart.toolTip}
              data={chart.data}
              keys={chart.keys}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              heightKey={keysHeight}
            />
          );
          break;
        case 5: // DividedBarChart for 'Team Allocation'
          keysHeight =
            chart.keys.length < 6
              ? 21
              : chart.keys.length >= 6 && chart.keys.length < 12
              ? 42
              : 86;
          chartComponent = (
            <DividedBarChart
              key={index}
              heading={chart.title}
              tooltip={chart.toolTip}
              data={chart.data}
              keys={chart.keys}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              showTotalValues={false}
              heightKey={keysHeight}
            />
          );
          break;
        case 8: // DividedBarChart for 'Team Time on Projects (Selected Week(s))'
          keysHeight =
            chart.keys.length < 6
              ? 21
              : chart.keys.length >= 6 && chart.keys.length < 12
              ? 42
              : 86;
          chartComponent = (
            <DividedBarChart
              key={index}
              heading={chart.title}
              tooltip={chart.toolTip}
              data={chart.data}
              keys={chart.keys}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              heightKey={keysHeight}
            />
          );
          break;

        case 3: // GroupedBarChart for 'Task vs Bug Ratio'
          chartComponent = (
            <CustomGroupedBarChart
              key={index}
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              Total1Text="Total tasks"
              Total2Text="Total bugs"
              heightKey={21}
            />
          );
          break;
        case 4: // GroupedBarChart for 'Task vs Bug Time Ratio'
          chartComponent = (
            <CustomGroupedBarChart
              key={index}
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              Total1Text="Total time on tasks"
              Total2Text="Total time on bugs"
              heightKey={21}
            />
          );
          break;
        case 6: // DividedBarChart for 'Total Time Spent by Teams'
          keysHeight =
            chart.keys.length < 6
              ? 21
              : chart.keys.length >= 6 && chart.keys.length < 12
              ? 42
              : 86;
          chartComponent = (
            <DividedBarChart
              key={index}
              heading={chart.title}
              tooltip={chart.toolTip}
              data={chart.data}
              keys={chart.keys}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              showTotalValues={false}
              heightKey={keysHeight}
            />
          );
          break;
        case 9: // GroupedBarChart for 'Task vs Bug Ratio (Selected Week(s))'
        case 10: // GroupedBarChart for 'Task vs Bug Time Ratio (Selected Week(s))'
          chartComponent = (
            <CustomGroupedBarChart
              key={index}
              data={chart.data}
              keys={chart.keys}
              heading={chart.title}
              tooltip={chart.toolTip}
              showTotalValues={true}
              indexByX={chart.indexByX}
              indexByY={chart.indexByY}
              Total1Text="Total time on tasks"
              Total2Text="Total time on bugs"
              heightKey={21}
            />
          );
          break;

        default:
          break;
      }

      chartComponents.push({
        chart: chartComponent,
        dataLength: chart.data.length,
      });
    });

    // Render the charts based on data length
    const renderedCharts = [];
    for (let i = 0; i < chartComponents.length; i++) {
      const { chart, dataLength } = chartComponents[i];
      console.log(chart, "chartComponents chart");
      const nextChart = chartComponents[i + 1];

      // Check if the chart should be full width or half width
      if (dataLength > 10) {
        // Full width for larger data
        renderedCharts.push(
          <div className="w-full" key={i}>
            {chart}
          </div>
        );
      } else if (nextChart && nextChart.dataLength <= 10) {
        const currentChartHeight = chartComponents[i].chart.props.heightKey;
        const nextChartHeight = nextChart.chart.props.heightKey;
        const maxHeight = Math.max(currentChartHeight, nextChartHeight);
        // Two charts side by side
        renderedCharts.push(
          <div className="flex w-full gap-6" key={i}>
            <div
              className="flex-1"
              style={{ flex: "1 1 50%", maxWidth: "50%" }}
            >
              {React.cloneElement(chart, { heightKey: maxHeight })}{" "}
              {/* Apply maxHeight */}
            </div>
            <div
              className="flex-1"
              style={{ flex: "1 1 50%", maxWidth: "50%" }}
            >
              {React.cloneElement(nextChart.chart, { heightKey: maxHeight })}{" "}
              {/* Apply maxHeight */}
            </div>
          </div>
        );
        i++; // Skip the next chart since it has been rendered side by side
      } else {
        // Last chart with no partner gets full width
        renderedCharts.push(
          <div className="w-full" key={i}>
            {chart}
          </div>
        );
      }
    }

    return renderedCharts;
  };

  return (
    <div className="px-6 py-4 max-h-[calc(100vh-115px)] overflow-y-scroll">
      <div className="flex flex-col gap-6">
        <div>
          <div className="out-500-16 mb-3">Projects Overview</div>
          <div className="ml-[-8px]">
            <CustomCarousel projects={projectList} />
          </div>
        </div>
        {/* Render the charts */}
        {renderCharts()}
      </div>
    </div>
  );
};

export default AllChartsScreen;
