import React, { useEffect, useMemo, useRef, useState } from "react";
import { ResponsiveBar } from "@nivo/bar";
import { schemeCategory10 } from "d3-scale-chromatic";
import { DotsVerticalIcon } from "../../Core/svgV2/DotsVertical";
import { Tooltip, Divider, Popover } from "antd";
import { ChevronIcon } from "../../Core/svgV2/Chevron";
import { useTooltip } from "@nivo/tooltip";
import { DeleteScheduleIcon } from "../../Core/svgV3/DeleteIcon";

type CustomBarComponentProps = {
  bar: {
    x: number;
    y: number;
    width: number;
    height: number;
    color: string;
    index: number;
    key: string;
    data: any;
  };
  isTopMost: boolean;
};

const DividedBarChart = ({
  data,
  keys,
  indexByX,
  indexByY = "Value",
  colorsScheme = schemeCategory10,
  heading = "Team time on projects",
  tooltip = "",
  showTotalValues = true,
  weeks = 1,
  heightKey = 21,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const scrollTolerance = 1; // Adjust if necessary
  console.log(heightKey, "heightKey");
  useEffect(() => {
    const handleScroll = () => {
      const container = scrollContainerRef.current;
      if (container) {
        const isAtLeftEdge = container.scrollLeft <= scrollTolerance;
        const isAtRightEdge =
          container.scrollWidth - container.scrollLeft <=
          container.clientWidth + scrollTolerance;

        setShowLeftArrow(!isAtLeftEdge);
        setShowRightArrow(!isAtRightEdge);
      }
    };

    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      handleScroll(); // Initial check

      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [keys, data]);

  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = direction === "left" ? -100 : 100;
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  // Find the top-most project for each bar group
  const topMostNames = useMemo(() => {
    const names: { [key: string]: string } = {};

    data.forEach((item) => {
      for (let i = keys.length - 1; i >= 0; i--) {
        const key = keys[i];
        if (item[key] !== undefined) {
          names[item[indexByX].trim()] = key.trim(); // Store the project name
          break;
        }
      }
    });

    return names;
  }, [data, keys, indexByX]);

  const colorMapping = useMemo(() => {
    const colorScale = colorsScheme;
    return keys.reduce((acc, key, i) => {
      acc[key] = colorScale[i % colorScale.length];
      return acc;
    }, {});
  }, [keys, colorsScheme]);

  const CustomBarComponent: React.FC<CustomBarComponentProps> = ({
    bar,
    isTopMost,
  }) => {
    const { x, y, width, height, color, data } = bar;

    console.log(bar, "Barko");

    // Adjusting the width and x positioning
    const adjustedWidth = width * 0.5;
    const adjustedX = x + (width - adjustedWidth) / 2;

    // Define a path to round only the top corners
    const roundedPath = `
      M${adjustedX},${y + height}
      L${adjustedX},${y + 6}
      Q${adjustedX},${y} ${adjustedX + 6},${y}
      L${adjustedX + adjustedWidth - 6},${y}
      Q${adjustedX + adjustedWidth},${y} ${adjustedX + adjustedWidth},${y + 6}
      L${adjustedX + adjustedWidth},${y + height}
      Z
    `;

    // Tooltip hook from Nivo
    const { showTooltipFromEvent, hideTooltip } = useTooltip();

    const handleMouseEnter = (event: React.MouseEvent<SVGElement>) => {
      showTooltipFromEvent(
        <div className="shadow-xl rounded-lg bg-white p-2">
          <span
            style={{
              display: "inline-block",
              backgroundColor: color,
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              marginRight: "5px",
            }}
          />
          {data.id}:
          <strong className="ml-0.5">
            {data.value}
            {indexByY === "Hours" ? "h" : ""}
          </strong>
        </div>,
        event
      );
    };

    const handleMouseLeave = () => {
      hideTooltip();
    };

    return (
      <g>
        {isTopMost ? (
          <path
            d={roundedPath}
            fill={color}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseEnter} // Ensure tooltip follows mouse movement
            onMouseLeave={handleMouseLeave}
          />
        ) : (
          <rect
            x={adjustedX}
            y={y}
            width={adjustedWidth}
            height={height}
            fill={color}
            onMouseEnter={handleMouseEnter}
            onMouseMove={handleMouseEnter} // Ensure tooltip follows mouse movement
            onMouseLeave={handleMouseLeave}
          />
        )}

        {/* Display the number inside the bar and rotate it by 90 degrees */}
        <text
          x={adjustedX + adjustedWidth / 2}
          y={y + height / 2 + 4}
          fill="white"
          fontSize="10"
          textAnchor="middle"
          transform={`rotate(-90, ${adjustedX + adjustedWidth / 2}, ${
            y + height / 2
          })`}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="cursor-default"
        >
          {data.value}
        </text>
      </g>
    );
  };

  const CustomLayer = ({ bars }) => (
    <>
      {bars.map((bar) => {
        const projectName = bar?.data?.id?.trim(); // Get the current bar project name (e.g., "API Development")
        const topMostProject = topMostNames[bar?.data?.indexValue?.trim()]; // Get the top-most project for this group
        const isTopMost = projectName === topMostProject; // Check if the current bar is the top-most

        return (
          <CustomBarComponent
            key={bar.key}
            bar={bar}
            isTopMost={isTopMost} // Pass the boolean for the top-most bar
          />
        );
      })}
    </>
  );

  // Define the number of items per page and per chunk (6 items per row, 12 items per page)
  const itemsPerPage = 12;

  // Calculate the start and end indices for slicing the array of keys
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentKeys = keys.slice(startIndex, endIndex);

  // Calculate the total number of pages based on keys length
  const totalPages = Math.ceil(keys.length / itemsPerPage);

  // Event handlers for navigating the carousel
  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i);

  const popoverContentThreeDots = (
    <div className="p-1 flex gap-2 items-center justify-start hover:bg-gray-200 cursor-pointer rounded p-2 ">
      <div>
        <DeleteScheduleIcon className="text-error-600" />
      </div>
      <div className="text-error-600">Delete Chart</div>
    </div>
  );

  const maxValue = weeks * 50; // Example: 1 week = 50, 2 weeks = 100, and so on.

  return (
    <div className="flex flex-col gap-4 p-4 bg-white rounded-xl">
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <div className="out-500-16 text-black">{heading}</div>
          <Tooltip
            title={<div className="">{tooltip}</div>}
            placement={"right"}
          >
            <div>
              {/* Tooltip info icon */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                fill="none"
                viewBox="0 0 12 12"
              >
                <path
                  stroke="#667085"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 8V6m0-2h.005M11 6A5 5 0 111 6a5 5 0 0110 0z"
                ></path>
              </svg>
            </div>
          </Tooltip>
        </div>

        <Popover
          content={popoverContentThreeDots}
          trigger="click"
          placement="bottom"
          arrow={false}
        >
          <DotsVerticalIcon className="text-gray-500" />
        </Popover>
      </div>
      {showTotalValues && (
        <div className="relative flex flex-col gap-2 max-w-[calc((100vw-400px)/2)]">
          <div className="flex overflow-x-auto" ref={scrollContainerRef}>
            <div className="flex gap-4 items-center whitespace-nowrap">
              {keys.map((key, i) => {
                const total = data.reduce(
                  (sum, item) => sum + (item[key] || 0),
                  0
                );
                return (
                  <div key={key} className="flex items-center gap-2">
                    <div>
                      <div className="out-500-10 text-gray-400 uppercase">
                        {key}
                      </div>

                      <div className="text-[#292927] text-2xl font-bold font-['Outfit'] leading-[30px] mt-1">
                        {total}
                        {indexByY === "Hours" && (
                          <span className="out-500-14 text-gray-500">h</span>
                        )}
                      </div>
                    </div>
                    {i !== keys.length - 1 && (
                      <Divider
                        type="vertical"
                        style={{ height: "40px", margin: "0" }}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          {/* Arrow Buttons */}
          {showLeftArrow && (
            <button
              onClick={() => scroll("left")}
              className="absolute left-[-15px] top-1/2 transform -translate-y-1/2 bg-white p-2 "
            >
              <ChevronIcon className="w-6 h-6 text-gray-500 rotate-90" />
            </button>
          )}
          {showRightArrow && (
            <button
              onClick={() => scroll("right")}
              className="absolute right-[-30px] top-1/2 transform -translate-y-1/2 bg-white p-2 mt-[-4px]"
            >
              <ChevronIcon className="w-6 h-6 text-gray-500 -rotate-90" />
            </button>
          )}
        </div>
      )}

      <div style={{ height: "400px", width: "100%" }}>
        <ResponsiveBar
          data={data}
          keys={keys}
          indexBy={indexByX}
          margin={{ top: 10, right: 0, bottom: 80, left: 50 }}
          padding={0.3}
          colors={({ id }) => colorMapping[id]}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: -30,
            legend: indexByX,
            legendPosition: "middle",
            legendOffset: 70,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: indexByY,
            legendPosition: "middle",
            legendOffset: -40,
            tickValues: Array.from(
              { length: maxValue / 10 + 1 },
              (_, i) => i * 10
            ), // Dynamic range increments of 10
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          tooltip={({ id, value, data }) => (
            <strong>
              {id}: {value}
            </strong>
          )}
          layers={[
            "grid",
            "axes",
            "markers",
            "legends",
            "annotations",
            CustomLayer, // Custom layer added
          ]}
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 10,
                  fill: "#555",
                },
              },
            },
            grid: {
              line: {
                stroke: "#ccc",
                strokeWidth: 1,
                strokeDasharray: "4 4",
              },
            },
          }}
          enableGridY={true}
          gridYValues={Array.from(
            { length: maxValue / 10 + 1 },
            (_, i) => i * 10
          )}
          minValue={0}
          maxValue={maxValue}
          animate={true}
        />
      </div>

      <div className="relative bg-gray-50 py-3 rounded flex flex-col justify-between flex-grow">
        {/* Display keys for the current page (12 keys max, 6 per row) */}
        <div
          className={`flex flex-col items-center flex-grow h-[${heightKey}px] items-center`}
        >
          <div className="flex gap-4 items-center justify-center flex-grow">
            {currentKeys.slice(0, 6).map((key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: colorMapping[key],
                    borderRadius: "50%",
                  }}
                />
                <span>{key}</span>
              </div>
            ))}
          </div>

          <div className="flex gap-4 items-center justify-center flex-grow">
            {currentKeys.slice(6, 12).map((key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                <div
                  style={{
                    width: "12px",
                    height: "12px",
                    backgroundColor: colorMapping[key],
                    borderRadius: "50%",
                  }}
                />
                <span>{key}</span>
              </div>
            ))}
          </div>
        </div>

        {/* Carousel pagination with arrows and page indicators */}
        {totalPages > 1 && (
          <div className="flex items-center justify-center flex-shrink-0">
            <button
              onClick={handlePreviousPage}
              disabled={currentPage === 0}
              className={`p-2 mt-1 ${
                currentPage === 0
                  ? "cursor-not-allowed text-gray-400"
                  : "text-gray-700"
              }`}
            >
              <ChevronIcon
                className="w-6 h-6"
                style={{ transform: "rotate(90deg)" }}
              />
            </button>

            <div className="flex items-center gap-2">
              {pageNumbers.map((pageIndex) => (
                <button
                  key={pageIndex}
                  onClick={() => setCurrentPage(pageIndex)}
                  className={` rounded-full ${
                    currentPage === pageIndex
                      ? "w-2 h-2 bg-primary-600"
                      : "w-1.5 h-1.5 bg-gray-400"
                  }`}
                />
              ))}
            </div>

            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
              className={`p-2 mt-[-2px] ${
                currentPage === totalPages - 1
                  ? "cursor-not-allowed text-gray-400"
                  : "text-gray-700"
              }`}
            >
              <ChevronIcon
                className="w-6 h-6"
                style={{ transform: "rotate(-90deg)" }}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DividedBarChart;
